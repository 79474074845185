/**
 * Created by jvitoria on 6/18/2015.
 */
/* ==============================================================================
 services.orders MODULE
 ============================================================================= */
angular.module('services.orders', ['core.ajax']);

/* ==============================================================================
 ordersService
 ============================================================================= */
angular.module('services.orders', [])
    .factory('ordersService', function ($q, ajax, apiService)
    {
        'use strict';
        return {
            getOpenOrders: function getOpenOrders(custKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'post',
                        url: '../api/GetOpenOrders/',
                        data: {
                            CustKey: custKey
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getOrdersContext: function getOrdersContext(custKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'post',
                        url: '../api/GetOrdersContext/',
                        data: {
                            CustKey: custKey
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getOrderLocationInfo: function getOrderLocationInfo(custLocKey, requestedDeliveryDate)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'post',
                        url: '../api/GetOrderLocationInfo/',
                        data: {
                            CustLocKey: custLocKey,
                            RequestedDeliveryDate: requestedDeliveryDate
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            getOrderDetails: function getOrderDetails(ordKey)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'post',
                        url: '../api/GetOrderDetails/',
                        data: {
                            OrdKey: ordKey
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            saveOrderDetails: function saveOrderDetails(orderDetails)
            {
                var deferred = $q.defer();
                var apiSvcSettings = new ApiServiceSettings(
                    {
                        method: 'post',
                        url: '../api/SaveOrderDetails/',
                        data: {
                            OrderDetails: orderDetails
                        }
                    }
                );
                apiService.execute(apiSvcSettings).then(
                    function (data)
                    {
                        deferred.resolve(data.Data);
                    }
                );
                return deferred.promise;
            },

            blankOrderHeaderObj: function blankOrderHeaderObj()
            {
                return {
                    Command: null,
                    Comment: null,
                    CustContKey: null,
                    CustContName: null,
                    CustContPhone: null,
                    CustLocAddress: null,
                    CustLocCity: null,
                    CustLocDesc: null,
                    CustLocKey: null,
                    CustLocState: null,
                    CustLocZip: null,
                    Errors: [],
                    LangID: 'EN',
                    OrdCreatedByContKey: null,
                    OrdDelivDirections: null,
                    OrdDelivNotes: null,
                    OrderDetails: [],
                    OrdKey: null,
                    OrdNumber: null,
                    OrdStatus: null,
                    OrdStatusKey: null,
                    OrdType: 0,
                    PONumber: null,
                    ReqDelivDateTime: null,
                    Timestamp: null
                };
            },

            blankOrderDetailsObj: function blankOrderDetailsObj()
            {
                return {
                    CustFuelTankKey: null,
                    ID: null,
                    MaxVolume: null,
                    OrderQty: 0,
                    OrdFuelKey: null,
                    OrdKey: null,
                    Product: null,
                    ProductKey: null,
                    TankNumber: null,
                    TimeStamp: null
                };
            }

        };
    });

