/**
 * Created by jvitoria on 9/5/14.
 */
angular.module("pnFuelCardsTransactions", ['core.objects'])
    .config(function ($routeProvider, templateProvider)
    {
        $routeProvider
            .when("/FuelCards/Transactions/TransactionDetails",
            {
                templateUrl: templateProvider.getTemplate("TransactionDetails"),
                controller: "transactionDetailsCtrl"
            })
            .when("/FuelCards/Transactions/TransactionSummary",
            {
                templateUrl: templateProvider.getTemplate("TransactionSummary"),
                controller: "transactionSummaryCtrl"
            })
            .when("/FuelCards/Transactions/TransactionByInvoice",
            {
                templateUrl: templateProvider.getTemplate("TransactionByInvoice"),
                controller: "transactionByInvoiceCtrl"
            })
            .when("/FuelCards/Transactions/TransactionTaxRecap",
            {
                templateUrl: templateProvider.getTemplate("TransactionTaxRecap"),
                controller: "transactionTaxRecapCtrl"
            })
            .when("/FuelCards/Transactions/TaxDetails",
            {
                templateUrl: templateProvider.getTemplate("TaxDetails"),
                controller: "taxDetailsCtrl"
            })
            .when("/FuelCards/Transactions/TaxSummary",
            {
                templateUrl: templateProvider.getTemplate("TaxSummary"),
                controller: "taxSummaryCtrl"
            })
            .when("/FuelCards/Transactions/TransactionAlerts",
            {
                templateUrl: templateProvider.getTemplate("TransactionAlerts"),
                controller: "transactionsAlertsCtrl"
            });
    })

    .controller('fuelCardsTransactionsSideMenuCtrl', function ($scope)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.locationInfo.sideMenuData)
                $scope.locationInfo.sideMenuData = {};
            if (!$scope.appInfo.fuelCards)
                $scope.appInfo.fuelCards = {};
            if (!$scope.appInfo.fuelCards)
                $scope.appInfo.fuelCards.transactionAlerts = {};
        };
    })

    .controller("transactionDetailsCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CardTransactions))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 41;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsTransactionsSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "0";
        };
    })

    .controller("transactionDetailsSideMenuCtrl", function ($scope, validatorSvc, reportingService, WebFunctions)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            $scope.$root.reportSettings.reportKey = 22;
            $scope.locationInfo.sideMenuData = {
                subMenu: "0",
                transactionDetails: {
                    cardNoDesc: '',
                    inclUnbilled: true,
                    inclBilled: false,
                    groupBy: "0",
                    inclAdditionalInfo: false,
                    viewCardNumbers: $scope.checkCurrentUserPermission(WebFunctions.ViewCardNumbers) || false
                }
            };
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.transactionDetails = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.transactionDetails;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var rptName = 'CardTransactionDetails';
            rptName += $scope.locationInfo.sideMenuData.transactionDetails.inclAdditionalInfo ? 'IAD' : '';

            var qs = "reportName=" + rptName +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-CardNoDesc=" + $scope.locationInfo.sideMenuData.transactionDetails.cardNoDesc +
                "&RP-BegDate=" + $scope.urlFormatDate($scope.dateSelector.startDate) +
                "&RP-EndDate=" + $scope.urlFormatDate($scope.dateSelector.endDate) +
                "&RP-InclBilled=" + $scope.locationInfo.sideMenuData.transactionDetails.inclBilled +
                "&RP-InclUnbilled=" + $scope.locationInfo.sideMenuData.transactionDetails.inclUnbilled +
                "&RP-GroupBy=" + $scope.locationInfo.sideMenuData.transactionDetails.groupBy +
                "&RP-ViewCardNumbers=" + $scope.locationInfo.sideMenuData.transactionDetails.viewCardNumbers;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };

        $scope.updateOptions = function ()
        {
            if ($scope.locationInfo.sideMenuData.transactionDetails.inclUnbilled === false && $scope.locationInfo.sideMenuData.transactionDetails.inclBilled === false)
            {
                $scope.locationInfo.sideMenuData.transactionDetails.inclUnbilled = true;
            }
        };
    })

    .controller("transactionSummaryCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CardTransactions))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 41;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsTransactionsSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "1";
        };
    })

    .controller("transactionSummarySideMenuCtrl", function ($scope, validatorSvc, reportingService, WebFunctions)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            $scope.$root.reportSettings.reportKey = 23;
            $scope.locationInfo.sideMenuData = {
                subMenu: "1",
                transactionSummary: {
                    cardNoDesc: '',
                    invoiceNo: '',
                    inclUnbilled: true,
                    enableUnbilled: true,
                    inclBilled: true,
                    enableBilled: true,
                    recap1: 4,
                    recap2: 0
                }
            };
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.transactionSummary = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
            $scope.updateOptions();
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.transactionSummary;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var qs = "reportName=" + 'CardTransactionSummary' +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-CardNoDesc=" + $scope.locationInfo.sideMenuData.transactionSummary.cardNoDesc +
                "&RP-InvoiceNo=" + $scope.locationInfo.sideMenuData.transactionSummary.invoiceNo +
                "&RP-BegDate=" + $scope.urlFormatDate($scope.dateSelector.startDate) +
                "&RP-EndDate=" + $scope.urlFormatDate($scope.dateSelector.endDate) +
                "&RP-InclBilled=" + $scope.locationInfo.sideMenuData.transactionSummary.inclBilled +
                "&RP-InclUnbilled=" + $scope.locationInfo.sideMenuData.transactionSummary.inclUnbilled +
                "&RP-Recap1=" + $scope.locationInfo.sideMenuData.transactionSummary.recap1 +
                "&RP-Recap2=" + $scope.locationInfo.sideMenuData.transactionSummary.recap2 +
                "&RP-ViewCardNumbers=" + $scope.checkCurrentUserPermission(WebFunctions.ViewCardNumbers) || false;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };

        $scope.updateOptions = function ()
        {
            if ($scope.locationInfo.sideMenuData.transactionSummary.invoiceNo === '')
            {
                $scope.locationInfo.sideMenuData.transactionSummary.enableUnbilled = true;
                $scope.locationInfo.sideMenuData.transactionSummary.enableBilled = true;
            }
            else
            {
                $scope.locationInfo.sideMenuData.transactionSummary.inclUnbilled = false;
                $scope.locationInfo.sideMenuData.transactionSummary.inclBilled = true;
                $scope.locationInfo.sideMenuData.transactionSummary.enableUnbilled = false;
                $scope.locationInfo.sideMenuData.transactionSummary.enableBilled = false;
            }

            if ($scope.locationInfo.sideMenuData.transactionSummary.inclUnbilled === false && $scope.locationInfo.sideMenuData.transactionSummary.inclBilled === false)
            {
                $scope.locationInfo.sideMenuData.transactionSummary.inclUnbilled = true;
            }
        };
    })

    .controller("transactionByInvoiceCtrl", function ($scope, validatorSvc, WebFunctions, templates)
    {
        'use strict';
        validatorSvc.clearValidationScopes();
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CardTransactions))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 41;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsTransactionsSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "2";
        };
    })

    .controller("transactionByInvoiceSideMenuCtrl", function ($scope, reportingService, WebFunctions, validatorSvc)
    {
        'use strict';
        $scope.initialize = function ()
        {
            $scope.$root.reportSettings.reportKey = 24;
            $scope.locationInfo.sideMenuData = {
                subMenu: "2",
                transactionByInvoice: {
                    invoiceNo: '',
                    groupBy: 0,
                    inclAdditionalInfo: false
                }
            };
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.transactionByInvoice = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.transactionByInvoice;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var rptName = 'CardTransactionsByInvoice';
            rptName += $scope.locationInfo.sideMenuData.transactionByInvoice.inclAdditionalInfo ? 'IAD' : '';

            var qs = "reportName=" + rptName +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-InvoiceNo=" + $scope.locationInfo.sideMenuData.transactionByInvoice.invoiceNo +
                "&RP-BegDate=" + $scope.urlFormatDate($scope.dateSelector.startDate) +
                "&RP-EndDate=" + $scope.urlFormatDate($scope.dateSelector.endDate) +
                "&RP-GroupBy=" + $scope.locationInfo.sideMenuData.transactionByInvoice.groupBy +
                "&RP-ViewCardNumbers=" + $scope.checkCurrentUserPermission(WebFunctions.ViewCardNumbers) || false;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })

    .controller("taxDetailsCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CardTransactions))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 41;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsTransactionsSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "3";
        };
    })

    .controller("taxDetailsSideMenuCtrl", function ($scope, validatorSvc, reportingService, FuelCardsService, WebFunctions, localizationSvc)
    {
        'use strict';
        var localStrings = localizationSvc.getCachedLocalizationsContainer("FuelCardsTransactionsLocalization");
        $scope.initialize = function ()
        {

            validatorSvc.clearValidationScopes();
            FuelCardsService.getTaxAuthorities(
                $scope.appInfo.webUser.CurrentAuthorization.CustKey
            ).then(
                function (data)
                {
                    $scope.appInfo.fuelCards.taxAuthorities = data.TaxAuthorities;
                    $scope.appInfo.fuelCards.taxAuthorities.unshift({ Key: 0, Description: localStrings.getLocalizedString("None") });

                    $scope.$root.reportSettings.reportKey = 25;
                    $scope.locationInfo.sideMenuData = {
                        subMenu: "3",
                        taxDetails: {
                            invoiceNo: '',
                            taxAuthKey: '0',
                            groupBy1: 1,
                            enableGroupBy1None: true,
                            groupBy2: 0,
                            enableGroupBy2: true,
                            groupBy3: 0,
                            enableGroupBy3: false,
                            inclTransDetail: true,
                            inclAdditionalInfo: false,
                            enableInclAdditionalInfo: true
                        }
                    };
                    
                }
            );
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.taxDetails = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
            $scope.updateOptions();
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.taxDetails;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var rptName = 'CardTaxDetails';
            rptName += $scope.locationInfo.sideMenuData.taxDetails.inclTransDetail ? 'IT' : 'NT';
            rptName = $scope.locationInfo.sideMenuData.taxDetails.inclAdditionalInfo ? 'CardTaxDetailsIAD' : rptName;

            var qs = "reportName=" + rptName +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-InvoiceNo=" + $scope.locationInfo.sideMenuData.taxDetails.invoiceNo +
                "&RP-BegDate=" + $scope.urlFormatDate($scope.dateSelector.startDate) +
                "&RP-EndDate=" + $scope.urlFormatDate($scope.dateSelector.endDate) +
                "&RP-TaxAuthKey=" + $scope.locationInfo.sideMenuData.taxDetails.taxAuthKey +
                "&RP-GroupBy1=" + $scope.locationInfo.sideMenuData.taxDetails.groupBy1 +
                "&RP-GroupBy2=" + $scope.locationInfo.sideMenuData.taxDetails.groupBy2 +
                "&RP-GroupBy3=" + $scope.locationInfo.sideMenuData.taxDetails.groupBy3;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };

        $scope.updateOptions = function ()
        {
            if ($scope.locationInfo.sideMenuData.taxDetails.inclTransDetail === true)
            {
                $scope.locationInfo.sideMenuData.taxDetails.enableInclAdditionalInfo = true;
                $scope.locationInfo.sideMenuData.taxDetails.enableGroupBy1None = true;
            }
            else
            {
                $scope.locationInfo.sideMenuData.taxDetails.enableInclAdditionalInfo = false;
                $scope.locationInfo.sideMenuData.taxDetails.inclAdditionalInfo = false;
                if ($scope.locationInfo.sideMenuData.taxDetails.groupBy1 === "0")
                {
                    $scope.locationInfo.sideMenuData.taxDetails.groupBy1 = "1";
                    $scope.notifier.show(localStrings.getLocalizedString("IncludeTransactionDetailsWarning"), 'warning');
                }
                $scope.locationInfo.sideMenuData.taxDetails.enableGroupBy1None = false;
            }

            if ($scope.locationInfo.sideMenuData.taxDetails.groupBy1 === "0")
            {
                $scope.locationInfo.sideMenuData.taxDetails.groupBy2 = "0";
                $scope.locationInfo.sideMenuData.taxDetails.enableGroupBy2 = false;
            }
            else
            {
                $scope.locationInfo.sideMenuData.taxDetails.enableGroupBy2 = true;
            }

            if ($scope.locationInfo.sideMenuData.taxDetails.groupBy2 === "0")
            {
                $scope.locationInfo.sideMenuData.taxDetails.groupBy3 = "0";
                $scope.locationInfo.sideMenuData.taxDetails.enableGroupBy3 = false;
            }
            else
            {
                $scope.locationInfo.sideMenuData.taxDetails.enableGroupBy3 = true;
            }
        };
    })

    .controller("taxSummaryCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CardTransactions))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 41;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsTransactionsSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "4";
        };
    })

    .controller("taxSummarySideMenuCtrl", function ($scope, validatorSvc, reportingService, WebFunctions)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            $scope.$root.reportSettings.reportKey = 26;
            $scope.locationInfo.sideMenuData = {
                subMenu: "4",
                taxSummary: {
                    invoiceNo: '',
                    groupBy1: "1",
                    groupBy2: "0",
                    groupBy3: "0",
                    enableGroupBy2: true,
                    enableGroupBy3: false
                }
            };
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.taxSummary = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
            $scope.updateOptions();
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.taxSummary;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var qs = "reportName=" + 'CardTaxSummary' +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-InvoiceNo=" + $scope.locationInfo.sideMenuData.taxSummary.invoiceNo +
                "&RP-BegDate=" + $scope.urlFormatDate($scope.dateSelector.startDate) +
                "&RP-EndDate=" + $scope.urlFormatDate($scope.dateSelector.endDate) +
                "&RP-GroupBy1=" + $scope.locationInfo.sideMenuData.taxSummary.groupBy1 +
                "&RP-GroupBy2=" + $scope.locationInfo.sideMenuData.taxSummary.groupBy2 +
                "&RP-GroupBy3=" + $scope.locationInfo.sideMenuData.taxSummary.groupBy3;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };

        $scope.updateOptions = function ()
        {
            if ($scope.locationInfo.sideMenuData.taxSummary.groupBy1 === "0")
            {
                $scope.locationInfo.sideMenuData.taxSummary.groupBy2 = "0";
                $scope.locationInfo.sideMenuData.taxSummary.enableGroupBy2 = false;
            }
            else
            {
                $scope.locationInfo.sideMenuData.taxSummary.enableGroupBy2 = true;
            }

            if ($scope.locationInfo.sideMenuData.taxSummary.groupBy2 === "0")
            {
                $scope.locationInfo.sideMenuData.taxSummary.groupBy3 = "0";
                $scope.locationInfo.sideMenuData.taxSummary.enableGroupBy3 = false;
            }
            else
            {
                $scope.locationInfo.sideMenuData.taxSummary.enableGroupBy3 = true;
            }
        };
    })

    .controller("transactionsAlertsCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CardTransactions))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 41;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsTransactionsSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "6";
        };
    })

    .controller("transactionsAlertsSideMenuCtrl", function ($scope, validatorSvc, reportingService, WebFunctions)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            if (!$scope.locationInfo.sideMenuData.transactionsAlerts) $scope.locationInfo.sideMenuData.transactionsAlerts = {};
            $scope.locationInfo.sideMenuData.transactionsAlerts.reportType = 'fuelCardsTransactionsAlerts';
            $scope.locationInfo.sideMenuData.transactionsAlerts.reportCount = '10';
            $scope.$root.reportSettings.reportKey = 28;
        };

        $scope.refreshTransactionAlerts = function ()
        {
            $scope.appInfo.fuelCards.transactionAlerts = {};
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.transactionsAlerts = $scope.reportSettings.loadOptions.controls;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.transactionsAlerts;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var qs = "reportName=CPAlertReportsArchive" +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-NumberToReturn=" + $scope.locationInfo.sideMenuData.transactionsAlerts.reportCount;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };
    })

    .controller("transactionTaxRecapCtrl", function ($scope, WebFunctions, templates)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.CardTransactions))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.menuLocation = 4;
            $scope.locationInfo.subMenuLocation = 41;
            $scope.locationInfo.sideMenu = templates.getTemplate("FuelCardsTransactionsSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = "7";
        };
    })

    .controller("transactionTaxRecapSideMenuCtrl", function ($scope, validatorSvc, reportingService, WebFunctions)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            $scope.kendo = {};
            $scope.$root.reportSettings.reportKey = 34;
            $scope.locationInfo.sideMenuData = {
                subMenu: "7",
                transactionTaxRecap: {
                    cardNoDesc: '',
                    inclUnbilled: true,
                    enableUnbilled: true,
                    inclBilled: true,
                    enableBilled: true,
                    groupBy: 0,
                    recap1: 0,
                    recap2: 0,
                    showTaxRecap: false
                }
            };
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.transactionTaxRecap = $scope.reportSettings.loadOptions.controls;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
            $scope.updateOptions();
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.transactionTaxRecap;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            var qs = "reportName=" + 'CardTransactionwithTaxRecaps' +
                "&RP-CustKey=" + $scope.appInfo.webUser.CurrentAuthorization.CustKey +
                "&RP-CardNoDesc=" + $scope.locationInfo.sideMenuData.transactionTaxRecap.cardNoDesc +
                "&RP-BegDate=" + $scope.urlFormatDate($scope.dateSelector.startDate) +
                "&RP-EndDate=" + $scope.urlFormatDate($scope.dateSelector.endDate) +
                "&RP-InclBilled=" + $scope.locationInfo.sideMenuData.transactionTaxRecap.inclBilled +
                "&RP-InclUnbilled=" + $scope.locationInfo.sideMenuData.transactionTaxRecap.inclUnbilled +
                "&RP-GroupBy=" + $scope.locationInfo.sideMenuData.transactionTaxRecap.groupBy +
                "&RP-RecapBy1=" + $scope.locationInfo.sideMenuData.transactionTaxRecap.recap1 +
                "&RP-RecapBy2=" + $scope.locationInfo.sideMenuData.transactionTaxRecap.recap2 +
                "&RP-ShowTaxRecapByGroupBy=" + $scope.locationInfo.sideMenuData.transactionTaxRecap.showTaxRecap;

            if (format)
                reportingService.exportReport(qs, format);
            else
                reportingService.renderReport(qs);
        };

        $scope.updateOptions = function ()
        {
            if ($scope.locationInfo.sideMenuData.transactionTaxRecap.inclUnbilled === false && $scope.locationInfo.sideMenuData.transactionTaxRecap.inclBilled === false)
            {
                $scope.locationInfo.sideMenuData.transactionTaxRecap.inclUnbilled = true;
            }
        };
    });