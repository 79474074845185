/**
 * Created by rhampton on 8/19/2014.
 */
angular.module("pnPaperwork", ['core.objects'])
    .config(function ($routeProvider, templateProvider)
    {

        $routeProvider
            .when("/Paperwork/EnterPaperwork/",
            {
                templateUrl: templateProvider.getTemplate("EnterPaperwork"),
                controller: "enterPaperworkStartCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                    }
                }
            })
            .when("/Paperwork/EnterPaperwork/:paperworkKey",
            {
                templateUrl: templateProvider.getTemplate("EnterPaperworkMeters"),
                controller: "enterPaperworkCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                    }
                }
            })
            .when("/Paperwork/EnterPaperworkDeliveries/:paperworkKey",
            {
                templateUrl: templateProvider.getTemplate("EnterPaperworkDeliveries"),
                controller: "enterPaperworkDeliveriesCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                    }
                }
            })
            .when("/Paperwork/EnterPaperworkTanks/:paperworkKey",
            {
                templateUrl: templateProvider.getTemplate("EnterPaperworkTanks"),
                controller: "enterPaperworkTanksCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                    }
                }
            })
            .when("/Paperwork/EnterPaperworkDealerForms/:paperworkKey",
            {
                templateUrl: templateProvider.getTemplate("EnterPaperworkDealerForms"),
                controller: "enterPaperworkDealerFormsCtrl"
            })
            .when("/Paperwork/ReviewPaperwork",
                {
                    templateUrl: templateProvider.getTemplate("ReviewPaperwork"),
                    controller: "reviewPaperworkCtrl",
                    resolve: {
                        localStrings: function (localizationSvc) {
                            return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                        }
                    }
                })
            .when("/Paperwork/Reports",
            {
                templateUrl: templateProvider.getTemplate("ReportsPaperwork"),
                controller: "reportsPaperworkStartCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                    }
                }
            })
            .when("/Paperwork/Reports/:reportName",
            {
                templateUrl: templateProvider.getTemplate("ReportsPaperwork"),
                controller: "paperworkReportCtrl"
            })
            .when("/Paperwork/ViewPaperwork/:paperworkKey",
            {
                templateUrl: templateProvider.getTemplate("ViewPaperwork"),
                controller: "enterPaperworkCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                    }
                }
            })
            .when("/Paperwork/ViewPaperworkTanks/:paperworkKey",
            {
                templateUrl: templateProvider.getTemplate("ViewPaperworkTanks"),
                controller: "enterPaperworkTanksCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                    }
                }
            })
            .when("/Paperwork/ViewPaperworkDeliveries/:paperworkKey",
            {
                templateUrl: templateProvider.getTemplate("ViewPaperworkDeliveries"),
                controller: "enterPaperworkDeliveriesCtrl",
                resolve: {
                    localStrings: function (localizationSvc) {
                        return localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
                    }
                }
            })
            .when("/Paperwork/ViewPaperworkDealerForms/:paperworkKey",
            {
                templateUrl: templateProvider.getTemplate("ViewPaperworkDealerForms"),
                controller: "enterPaperworkDealerFormsCtrl"
            })
            .when("/Paperwork/InventorySummary",
            {
                templateUrl: templateProvider.getTemplate("InventorySummary"),
                controller: "inventorySummaryCtrl"
            })
            .when("/Paperwork/InventorySummary/:sitekey/:paperworkDate",
            {
                templateUrl: templateProvider.getTemplate("InventorySummary"),
                controller: "inventorySummaryCtrl"
            });
    })
    .controller('reviewPaperworkSideMenuCtrl', function ($scope, PaperworkService, PaperworkReportsService, WebFunctions, $location)
    {
        'use strict';
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }

            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenuData.subMenu = '2';
            $scope.appInfo.paperwork.context = $scope.appInfo.paperwork.context || {};
            $scope.appInfo.paperwork.reports = $scope.appInfo.paperwork.reports || {};
            $scope.locationInfo.sideMenuData.reviewPaperwork = {};
            $scope.locationInfo.sideMenuData.reviewPaperwork.loaded = false;
            $scope.locationInfo.sideMenuData.reviewPaperwork.unposted = true;
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;

            // There is a race condition when reloading the page
            // Check if the date selector is undefined, if it is then initialize it
            if (angular.isUndefinedOrNullOrEmpty($scope.dateSelector)) {
                $scope.initializeDateSelector();
            }

            $scope.dateSelector.overrideDefaults = true;
            $scope.dateSelector.displaySelector = true;
            $scope.dateSelector.maxNumOfDays = 90;
            $scope.dateSelector.selection = 11;

            PaperworkService.getPaperworkContext($scope.appInfo.webUser.CurrentAuthorization.CustContKey, true).then(
                function (data) {
                    $scope.appInfo.paperwork.context.paperworkSites = data.PaperworkSites;
                    $scope.appInfo.paperwork.context.selectedSite = data.SelectedSiteKey;
                    $scope.locationInfo.sideMenuData.reviewPaperwork.loaded = true;
                    $scope.appInfo.paperwork.context.lockPaperworkAfterDru = data.LockPaperworkAfterDru;

                    $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance = data.CalculatedPriceToPriorDaysPriceVariance;
                    $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance = data.CalculatedPriceToCapturedRetailPriceVariance;
                    $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance = data.NetSalesDollarsToCapturedRetailPriceNetUnitsVariance;
                    $scope.setSiteDescByKey();

                    $scope.locationInfo.showEnterPaperworkMenuItem = $scope.appInfo.paperwork.context.paperworkSites[0] ?
                        $scope.appInfo.paperwork.context.paperworkSites[0].CanEnterPaperwork :
                        false;

                    if (!$scope.locationInfo.showEnterPaperworkMenuItem) {
                        $scope.locationInfo.sideMenuData.subMenu = '2';
                        $location.path("/Paperwork/ReviewPaperwork");
                    }
                },
                function (err) {
                    $scope.locationInfo.sideMenuData.reviewPaperwork.loaded = true;
                    //TODO: notify somebody
                }
            );

            PaperworkReportsService
                .getPaperworkReports($scope.appInfo.webUser.CurrentAuthorization.CustContKey, false)
                .then(function (data) {
                    if (data.Successful && !angular.isUndefinedOrNullOrEmpty(data.PaperworkReports)) {
                        $scope.appInfo.paperwork.reports.paperworkReports = data.PaperworkReports;
                    }
                }
            );
        };

        $scope.setSiteDescByKey = function ()
        {
            angular.forEach($scope.appInfo.paperwork.context.paperworkSites, function (site, index)
            {
                if (site.SiteKey === $scope.appInfo.paperwork.context.selectedSite)
                    $scope.appInfo.paperwork.context.selectedSiteObj = site;
            });
        };
    })
    .controller('enterPaperworkSideMenuCtrl', function ($scope, PaperworkService, $filter, WebFunctions, $rootScope, localizationSvc)
    {
        'use strict';
        var localStrings = localizationSvc.getCachedLocalizationsContainer("PaperworkLocalization");
        $scope.retailWarningMessage = localStrings.getLocalizedString("WarningRetailPrice");
        $scope.averageWarningMessage = localStrings.getLocalizedString("WarningAveragePrice");
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.sideMenuData.paperwork = {};
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;
        };

        $scope.setupAppNavigation = function ()
        {
            angular.forEach($scope.appInfo.paperwork.apps, function (app, index)
            {
                if (app.MenuCode === 'DREFD')
                {
                    $scope.locationInfo.sideMenuData.paperwork.deliveries = app;
                }
                if (app.MenuCode === 'DREGM')
                {
                    $scope.locationInfo.sideMenuData.paperwork.meters = app;
                }
                if (app.MenuCode === 'DREGS')
                {
                    $scope.locationInfo.sideMenuData.paperwork.tanks = app;
                }
                if (app.DealerFormVersionKey > 0)
                {
                    app.MenuCode = 'DRDFE';
                    $scope.locationInfo.sideMenuData.paperwork.forms = app;
                    $scope.appInfo.paperwork.form.AppKey = app.Key;
                }

                //TODO: Navigate to first available app

            });
        };

        $scope.openNewPaperworkBatch = function ()
        {
            PaperworkService.OpenPaperworkBatch(
                $scope.appInfo.paperwork.context.selectedSite,
                $scope.urlFormatDate($scope.locationInfo.sideMenuData.paperwork.date, true),
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.Id,
                false
            ).then(function (data)
                {
                    $scope.appInfo.paperwork.batch = data.PaperworkBatch.PaperworkBatch;
                    $scope.appInfo.paperwork.apps = data.PaperworkBatch.PaperworkApps;
                    $scope.appInfo.paperwork.form = data.PaperworkBatch.PaperworkForm;
                    $scope.appInfo.paperwork.siteInfo = data.PaperworkBatch.PaperworkSiteInfo;
                    $scope.appInfo.paperwork.paperworkKey = $scope.appInfo.paperwork.batch.Key;
                    $scope.setupAppNavigation();

                $rootScope.$broadcast('event:NewPaperworkBatchLoaded', {});
            });
        };

        var pwLoadedListener = $rootScope.$on('event:PaperworkLoaded', function (event, viewOnly, data)
        {
            $scope.setupAppNavigation();
        });

        

        $scope.$on('$destroy', pwLoadedListener);

        $scope.viewDeliveries = function ()
        {
            $rootScope.$broadcast('event:NavigateToDeliveries', '');
        };

        $scope.viewMeters = function ()
        {
            $rootScope.$broadcast('event:NavigateToMeters', '');
        };

        $scope.viewTanks = function ()
        {
            $rootScope.$broadcast('event:NavigateToTanks', '');
        };

        $scope.viewForms = function ()
        {
            $rootScope.$broadcast('event:NavigateToForms', '');
        };

    })
    .controller('inventorySummarySideMenuCtrl', function ($scope, PaperworkService, PaperworkReportsService, $routeParams, WebFunctions, reportingService, validatorSvc)
    {
        'use strict';
        $scope.initialize = function ()
        {
            validatorSvc.clearValidationScopes();
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }           

            $scope.$root.reportSettings.reportKey = 19;
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenuData.inventorySummary = {};
            $scope.locationInfo.sideMenuData.subMenu = '3';
            $scope.locationInfo.sideMenuData.inventorySummary.reportType = 'PaperworkSummary ';
            $scope.appInfo = $scope.appInfo || {};
            $scope.appInfo.paperwork = $scope.appInfo.paperwork || {};
            $scope.appInfo.paperwork.context = $scope.appInfo.paperwork.context || {};
            $scope.appInfo.paperwork.reports = $scope.appInfo.paperwork.reports || {};

            $scope.appInfo.paperwork.context.selectedSiteObj = { SiteKey: -1 };

            // There is a race condition when reloading the page
            // Check if the date selector is undefined, if it is then initialize it
            if (angular.isUndefinedOrNullOrEmpty($scope.dateSelector)) {
                $scope.initializeDateSelector();
            }

            $scope.dateSelector.overrideDefaults = true;
            $scope.dateSelector.displaySelector = false;
            $scope.dateSelector.maxNumOfDays = 90;
            $scope.dateSelector.selection = 14;

            PaperworkService
                .getPaperworkContext($scope.appInfo.webUser.CurrentAuthorization.CustContKey, true)
                .then(function (data)
                {
                    $scope.appInfo.paperwork.context.paperworkSites = data.PaperworkSites;
                    $scope.appInfo.paperwork.context.lockPaperworkAfterDru = data.LockPaperworkAfterDru;

                    $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance = data.CalculatedPriceToPriorDaysPriceVariance;
                    $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance = data.CalculatedPriceToCapturedRetailPriceVariance;
                    $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance = data.NetSalesDollarsToCapturedRetailPriceNetUnitsVariance;

                    if (!$scope.setSiteAndDate())
                    {
                        $scope.appInfo.paperwork.context.selectedSiteObj.SiteKey = data.SelectedSiteKey;
                        $scope.dateSelector.startDate = $scope.formatDate(data.NextPaperworkDate);
                    }

                    $scope.locationInfo.showEnterPaperworkMenuItem = $scope.appInfo.paperwork.context.paperworkSites[0] ?
                        $scope.appInfo.paperwork.context.paperworkSites[0].CanEnterPaperwork :
                        false;

                    if (!$scope.locationInfo.showEnterPaperworkMenuItem)
                    {
                        $scope.locationInfo.sideMenuData.subMenu = '2';
                        $location.path("/Paperwork/ReviewPaperwork");
                    }
                    $scope.applyAction();
                },
                function (err)
                {
                    $scope.locationInfo.sideMenuData.reviewPaperwork.loaded = true;
                    //TODO: notify somebody
                }
            );

            PaperworkReportsService
                .getPaperworkReports($scope.appInfo.webUser.CurrentAuthorization.CustContKey, false)
                .then(function (data) {
                    if (data.Successful && !angular.isUndefinedOrNullOrEmpty(data.PaperworkReports)) {
                        $scope.appInfo.paperwork.reports.paperworkReports = data.PaperworkReports;
                    }
                }
            );
        };

        $scope.$root.reportSettings = $scope.$root.reportSettings || {};
        $scope.$root.reportSettings.unwrapReportSettings = function ()
        {
            $scope.locationInfo.sideMenuData.inventorySummary = $scope.reportSettings.loadOptions.controls;
            $scope.appInfo.paperwork.context.selectedSiteObj.SiteKey = $scope.reportSettings.loadOptions.selectedSite;
            $scope.dateSelector = $scope.reportSettings.loadOptions.date;
        };

        $scope.$root.reportSettings.wrapReportSettings = function ()
        {
            $scope.reportSettings.saveOptions.controls = $scope.locationInfo.sideMenuData.inventorySummary;
            $scope.reportSettings.saveOptions.selectedSite = $scope.appInfo.paperwork.context.selectedSiteObj.SiteKey;
            $scope.reportSettings.saveOptions.date = $scope.dateSelector;
        };

        $scope.setNextPaperworkDate = function (e)
        {
            if ($scope.setSiteAndDate())
                return;

            PaperworkService.getNextPaperworkDate(e.sender.dataItem(e.sender.select()).SiteKey, true).then(
                function (data)
                {
                    $scope.dateSelector.startDate = $scope.formatDate(data);
                }
            );
        };

        $scope.setSiteAndDate = function ()
        {
            if ($routeParams.sitekey && $routeParams.paperworkDate)
            {
                $scope.appInfo.paperwork.context.selectedSiteObj = {SiteKey: -1};

                $scope.appInfo.paperwork.context.selectedSiteObj.SiteKey = parseInt($routeParams.sitekey, 10);
                $scope.dateSelector.startDate = $scope.formatDate($routeParams.paperworkDate);
                return true;
            }
            else
                return false;
        };

        $scope.$on('exportingReport', function (event, args)
        {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format)
        {
            if (!validatorSvc.executeValidations()) return;
            PaperworkService.ValidatePaperworkSiteDateForContact(
                $scope.appInfo.paperwork.context.selectedSiteObj.SiteKey,
                $scope.urlFormatDate($scope.dateSelector.startDate, true),
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                false // not for entry
            ).then(function (data)
                {
                    var qs = "reportName=" + $scope.locationInfo.sideMenuData.inventorySummary.reportType +
                        "&RP-SiteKey=" + $scope.appInfo.paperwork.context.selectedSiteObj.SiteKey +
                        "&RP-PWDate=" + $scope.dateSelector.startDate;

                    if (format)
                        reportingService.exportReport(qs, format);
                    else
                        reportingService.renderReport(qs);
                });

        };
    })
    .controller('reportsPaperworkStartCtrl', function ($scope, WebFunctions, templates, localStrings, PaperworkReportsService)
    {
        'use strict';

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }
            
            $scope.appInfo = $scope.appInfo || {};
            $scope.appInfo.paperwork = {
                context: {},
                paperworkKey: 0,
                reports: {}
            };
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.appInfo.paperwork.reports.paperworkReports = [];
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportsSideMenu");
            $scope.locationInfo.sideMenuData.paperwork = {};
            $scope.locationInfo.showEnterPaperworkMenuItem = false;
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 31;

            PaperworkReportsService
                .getPaperworkReports($scope.appInfo.webUser.CurrentAuthorization.CustContKey, false)
                .then(function (data) {
                    if (data.Successful && !angular.isUndefinedOrNullOrEmpty(data.PaperworkReports)) {
                        $scope.appInfo.paperwork.reports.paperworkReports = $scope.localizeReports(data.PaperworkReports).sort($scope.compareDisplayNames);
                    }
                    else {
                        $scope.goTo("/Paperwork/EnterPaperwork");
                        $scope.modalifier.showSetupError();
                    }
                }
            );
        };

        $scope.localizeReports = function (reports) {
            angular.forEach(reports, function (report) {
                report.DisplayName = localStrings.getLocalizedString('SSRSReport' + report.Name);
            });

            return reports;
        };

        $scope.compareDisplayNames = function (a, b) {
            return a.DisplayName.localeCompare(b.DisplayName);
        };
    })
    .controller('enterPaperworkStartCtrl', function ($scope, $location, PaperworkService, PaperworkReportsService, WebFunctions, validatorSvc, templates, localStrings)
    {
        'use strict';
        $scope.averageWarningMessage = localStrings.getLocalizedString("WarningAveragePrice");
        $scope.retailWarningMessage = localStrings.getLocalizedString("WarningRetailPrice");
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }

            validatorSvc.clearValidationScopes();

            $scope.appInfo = $scope.appInfo || {};
            $scope.appInfo.paperwork = {
                context: {},
                paperworkKey: 0,
                reports: {}
            };
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.appInfo.paperwork.reports.paperworkReports = [];
            $scope.locationInfo.sideMenu = templates.getTemplate("PaperworkSideMenu");
            $scope.locationInfo.sideMenuData.paperwork = {};
            $scope.locationInfo.sideMenuData.subMenu = '1';
            $scope.locationInfo.showEnterPaperworkMenuItem = false;
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;

            // There is a race condition when reloading the page
            // Check if the date selector is undefined, if it is then initialize it
            if (angular.isUndefinedOrNullOrEmpty($scope.dateSelector)) {
                $scope.initializeDateSelector();
            }

            $scope.dateSelector.displaySelector = true;
            $scope.dateSelector.overrideDefaults = true;
            $scope.dateSelector.maxNumOfDays = 90;
            $scope.dateSelector.selection = 11;

            PaperworkService.getPaperworkContext($scope.appInfo.webUser.CurrentAuthorization.CustContKey, false).then(
                function (data)
                {
                    if (data.Successful && !angular.isUndefinedOrNullOrEmpty(data.PaperworkSites))
                    {
                        $scope.appInfo.paperwork.context.paperworkSites = data.PaperworkSites;
                        $scope.appInfo.paperwork.context.selectedSite = data.SelectedSiteKey;
                        $scope.appInfo.paperwork.context.lockPaperworkAfterDru = data.LockPaperworkAfterDru;
                        $scope.locationInfo.sideMenuData.paperwork.date = $scope.formatDate(data.NextPaperworkDate);
                        $scope.locationInfo.sideMenuData.paperwork.minDate = $scope.formatDate(data.HistoryLimit);
                        $scope.locationInfo.sideMenuData.paperwork.maxDate = $scope.formatDate(data.FutureLimit);
                        $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance = data.CalculatedPriceToPriorDaysPriceVariance;
                        $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance = data.CalculatedPriceToCapturedRetailPriceVariance;
                        $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance = data.NetSalesDollarsToCapturedRetailPriceNetUnitsVariance;
                        $scope.setSiteDescByKey();

                        $scope.locationInfo.showEnterPaperworkMenuItem = $scope.appInfo.paperwork.context.paperworkSites[0] ?
                            $scope.appInfo.paperwork.context.paperworkSites[0].CanEnterPaperwork :
                            false;

                        if (!$scope.locationInfo.showEnterPaperworkMenuItem)
                        {
                            $scope.locationInfo.sideMenuData.subMenu = '2';
                            $location.path("/Paperwork/ReviewPaperwork");
                        }
                    }
                    else
                        $scope.modalifier.showSetupError();
                }
            );

            PaperworkReportsService
                .getPaperworkReports($scope.appInfo.webUser.CurrentAuthorization.CustContKey, false)
                .then(function (data) {
                    if (data.Successful && !angular.isUndefinedOrNullOrEmpty(data.PaperworkReports)) {
                        $scope.appInfo.paperwork.reports.paperworkReports = data.PaperworkReports;
                    }
                }
            );
        };

        $scope.setNextPaperworkDate = function (e)
        {
            PaperworkService.getNextPaperworkDate(e.sender.dataItem(e.sender.select()).SiteKey, false).then(
                function (data)
                {
                    $scope.dateSelector.startDate = $scope.formatDate(data);
                    $scope.locationInfo.sideMenuData.paperwork.date = $scope.dateSelector.startDate;
                }
            );
        };

        $scope.setSiteDescByKey = function ()
        {
            angular.forEach($scope.appInfo.paperwork.context.paperworkSites, function (site, index)
            {
                if (site.SiteKey === $scope.appInfo.paperwork.context.selectedSite)
                    $scope.appInfo.paperwork.context.selectedSiteObj = site;
            });
        };

        $scope.openNewPaperworkBatch = function ()
        {
            if (!validatorSvc.executeValidations())
                return;

            PaperworkService.OpenPaperworkBatch(
                $scope.appInfo.paperwork.context.selectedSite,
                $scope.urlFormatDate($scope.locationInfo.sideMenuData.paperwork.date, true),
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                $scope.appInfo.webUser.Id,
                false
            ).then(function (data)
                {
                    $scope.appInfo.paperwork.batch = data.PaperworkBatch.PaperworkBatch;
                    $scope.appInfo.paperwork.apps = data.PaperworkBatch.PaperworkApps;
                    $scope.appInfo.paperwork.form = data.PaperworkBatch.PaperworkForm;
                    $scope.appInfo.paperwork.siteInfo = data.PaperworkBatch.PaperworkSiteInfo;
                    $scope.appInfo.paperwork.paperworkKey = $scope.appInfo.paperwork.batch.Key;

                    if($scope.appInfo.paperwork.batch.SubmitStatus >= "3")
                        $scope.goTo("/Paperwork/ViewPaperwork/" + $scope.appInfo.paperwork.paperworkKey);
                    else
                    $scope.goTo("/Paperwork/EnterPaperwork/" + $scope.appInfo.paperwork.paperworkKey);
            });
        };
    })
    .controller('reviewPaperworkCtrl', function ($scope, validatorSvc, $location, PaperworkService, WebFunctions, $filter, templates, localStrings, globalizationSvc)
    {
        'use strict';
        validatorSvc.clearValidationScopes();
        $scope.kendo = {};
        $scope.kendo.paperworkHistoryGrid = {};
        $scope.kendo.paperworkHistoryGridOptions = {
            autoBind: false,
            columns: [
                {
                    field: "PaperworkDateFormatted",
                    title: localStrings.getLocalizedString("PaperworkDate"),
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "SubmitStatusDesc",
                    title: localStrings.getLocalizedString("Status"),
                    attributes: {class: "alignLeft"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    field: "TotalFuelSales",
                    title: localStrings.getLocalizedString("DailyTotal"),
                    attributes: {class: "alignRight"},
                    filterable: {
                        cell: {
                            operator: "contains",
                            showOperators: false
                        }
                    }
                },
                {
                    template: "<a ng-click='loadPaperwork(dataItem, false)'><i class='fa fa-search'></i></a>",
                    width: 50
                },
                {
                    template: "<a ng-click='loadPaperwork(dataItem, true)' ng-if='allEntry(dataItem)'><i class='fa fa-pencil'></i></a>",
                    width: 50
                }
            ],
            dataSource: [],
            editable: false,
            filterable: {
                mode: "row", extra: false
            },
            navigatable: true,
            pageable: false,
            scrollable: false,
            selectable: "row",
            sortable: true,
            width: '50%'
        };

        $scope.kendo.paperworkHistoryGridInit = function (ds)
        {
            $scope.kendo.paperworkHistoryGridDS = new kendo.data.DataSource({
                data: ds
            });

            $scope.kendo.paperworkHistoryGridDS.read();
            $scope.kendo.paperworkHistoryGrid.dataSource.data($scope.kendo.paperworkHistoryGridDS.data());
        };

        $scope.reviewPaperwork = {};
        $scope.selectedReviewSite = {};
        $scope.viewPaperworkHistory = false;
        $scope.reviewLoaded = false;
        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }
            $scope.noRecordsFound = false;
            $scope.appInfo = $scope.appInfo || {};
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaperworkSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = '2';
            $scope.reviewLoaded = true;
        };

        $scope.allEntry = function (batch)
        {
            return (batch.PaperworkDate > batch.InvtyCOGSDate) &&
                (batch.PaperworkDate > batch.FuelCOGSDate) &&
                (batch.SubmitStatus < 3 || !$scope.appInfo.paperwork.context.lockPaperworkAfterDru);
        };

        $scope.$parent.applyAction = function ()
        {
            if (!validatorSvc.executeValidations()) return;

            $scope.modalifier.hide();
            PaperworkService.getPaperworkBatchesByDate(
                $scope.appInfo.paperwork.context.selectedSiteObj.SiteKey,
                $scope.urlFormatDate($scope.dateSelector.startDate, true),
                $scope.urlFormatDate($scope.dateSelector.endDate, true),
                $scope.locationInfo.sideMenuData.reviewPaperwork.unposted ? 1 : 0
            ).then(function (data)
                {
                    if (data.length === 0)
                    {
                        $scope.modalifier.showNoResults(localStrings.getLocalizedString("PaperworkHistory"));
                    }

                    data.each(function (item)
                    {
                        item.PaperworkDateFormatted = globalizationSvc.formatDateTime(item.PaperworkDate, 'd');
                        item.TotalFuelSales = globalizationSvc.formatCurrency(item.TotalFuelSales);
                    });

                    $scope.kendo.paperworkHistoryGridInit(data);
                    $scope.viewPaperworkHistory = true;
                });
        };

        $scope.loadPaperwork = function (item, isEntry)
        {
            PaperworkService.ValidatePaperworkSiteDateForContact(
                $scope.appInfo.paperwork.context.selectedSiteObj.SiteKey,
                item.PaperworkDateFormatted,
                $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                isEntry || false
            ).then(function (data)
                {
                    if (isEntry)
                        $location.path("/Paperwork/EnterPaperwork/" + item.Key);
                    else
                        $location.path("/Paperwork/ViewPaperwork/" + item.Key);
                });
        };
    })
    .controller('enterPaperworkCtrl', function ($scope, $rootScope, $routeParams, PaperworkService, $filter, $location, $timeout, WebFunctions,
                                                PaperworkMetersService, validatorSvc, templates, localStrings, globalizationSvc)
    {
        'use strict';
        $scope.averageWarningMessage = localStrings.getLocalizedString("WarningAveragePrice");
        $scope.retailWarningMessage = localStrings.getLocalizedString("WarningRetailPrice");
        $scope.appInfo.paperwork.paperworkKey = 0;
        $scope.appInfo.paperwork.showRetailPrice = false;
        $scope.appInfo.paperwork.showEndingDollar = false;
        $scope.appInfo.paperwork.showNetDollars = false;
        $scope.appInfo.paperwork.showEndingUnits = false;
        $scope.appInfo.paperwork.showNetUnits = false;
        $scope.appInfo.paperwork.showAvgPrice = false;
        $scope.appInfo.paperwork.showBeginMeters = false;

        $scope.appInfo.paperwork.meters = {};

        $scope.modalInfo = {};
        $scope.metersLoaded = false;

        $scope.kendo = {};

        $scope.kInputOptions = {
            culture: globalizationSvc.getLocale(),
            format: "n",
            decimals: 2,
            spinners: false
        };

        $scope.MaxUnits = 100000000;

        // controller destructor
        $scope.$on("$destroy", function handler()
        {
            $rootScope.scopesToValidate = [];
        });

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }         

            $scope.appInfo.paperwork.viewOnly = $location.$$path.indexOf('/ViewPaperwork/') > -1;

            $scope.metersEditable = true;

            if ($routeParams.paperworkKey)
            {
                $scope.appInfo.paperwork.paperworkKey = $routeParams.paperworkKey;
            }

            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenu = templates.getTemplate("PaperworkSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = '1';
//            $scope.getWebProfileMessage(19);
            $scope.appInfo.paperwork.batch = {};

            if (!$scope.appInfo.paperwork.context)
            {
                PaperworkService.getPaperworkContext($scope.appInfo.webUser.CurrentAuthorization.CustContKey, true).then(
                    function (data)
                    {
                        $scope.appInfo.paperwork.context = {};
                        $scope.appInfo.paperwork.context.paperworkSites = data.PaperworkSites;
                        $scope.appInfo.paperwork.context.selectedSite = data.SelectedSiteKey;
                        $scope.appInfo.paperwork.context.lockPaperworkAfterDru = data.LockPaperworkAfterDru;

                        $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance = data.CalculatedPriceToPriorDaysPriceVariance;
                        $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance = data.CalculatedPriceToCapturedRetailPriceVariance;
                        $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance = data.NetSalesDollarsToCapturedRetailPriceNetUnitsVariance;

                        $scope.locationInfo.showEnterPaperworkMenuItem = $scope.appInfo.paperwork.context.paperworkSites[0] ?
                            $scope.appInfo.paperwork.context.paperworkSites[0].CanEnterPaperwork :
                            false;

                        if (!$scope.locationInfo.showEnterPaperworkMenuItem)
                        {
                            $scope.locationInfo.sideMenuData.subMenu = '2';
                            $location.path("/Paperwork/ReviewPaperwork");
                        }
                    }
                );
            } else
            {

            }


            if ($scope.appInfo.paperwork.paperworkKey > 0)
            {
                //We are loading a known existing paperwork batch
                PaperworkService.OpenPaperworkBatchByKey(
                    $scope.appInfo.paperwork.paperworkKey,
                    $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                    $scope.appInfo.webUser.Id,
                    $scope.appInfo.paperwork.viewOnly
                ).then(function (data)
                    {
                        $scope.appInfo.paperwork.batch = data.PaperworkBatch.PaperworkBatch;
                        $scope.appInfo.paperwork.apps = data.PaperworkBatch.PaperworkApps;
                        $scope.appInfo.paperwork.form = data.PaperworkBatch.PaperworkForm;
                        $scope.appInfo.paperwork.siteInfo = data.PaperworkBatch.PaperworkSiteInfo;

                        $rootScope.$broadcast('event:PaperworkLoaded', $scope.appInfo.paperwork.viewOnly, $scope.appInfo.paperwork.paperworkKey);

                        var nextApp = true;
                        angular.forEach($scope.appInfo.paperwork.apps, function (app, index)
                        {
                            if (app.MenuCode === 'DREGM')
                            {
                                $scope.loadMeters();
                                nextApp = false;
                            }
                        });
                        if (nextApp)
                        {
                            if ($scope.appInfo.paperwork.viewOnly)
                                $scope.goTo("/Paperwork/ViewPaperworkTanks/" + $scope.appInfo.paperwork.paperworkKey);
                            else
                                $scope.goTo("/Paperwork/EnterPaperworkTanks/" + $scope.appInfo.paperwork.paperworkKey);
                        }
                    }, function ()
                    {
                        $location.path("/Paperwork/ReviewPaperwork");
                    });
            }
            $scope.locationInfo.sideMenuData.pwSubMenu = '1';
            $scope.appInfo.paperwork.meterIndex = 0;


        };

        var gridColEditorInput = function (container, options)
        {

            var fieldName = "'" + options.field + "'";

            var required = $(container).attr('required'); // field is required

            // For some browsers, `attr` is undefined; for others,
            // `attr` is false.  Check for both.

            var input = $('<input />');
            input.kendoNumericTextBox({
                culture: globalizationSvc.getLocale(),
                format: 'n3',
                decimals: 3
            });

            input.attr('name', options.field);
            input.attr('data-bind', 'value:' + options.field);
            input.attr('ng-blur', 'gridInputCellOnBlur(dataItem, ' + fieldName + ')');
            input.attr('style', 'border: "none"');

            if (typeof required !== typeof undefined && required !== false)
            {
                input.attr('required');
                input.attr('data-Required-msg="Required"');
            }

            input.appendTo(container);
        };

        var cellIndex = "";
        var rowIndex = "";

        var gridColNumber = function (field, format)
        {
            return '<label>#= kendo.toString(' + field + ', "' + format + '", "' + globalizationSvc.getLocale() +'")#</label>';
        };

        $scope.initializeMetersGrid = function ()
        {

            $scope.metersGridDS = new kendo.data.DataSource({
                schema: {
                    model: {
                        id: "DispenserNo",
                        fields: {
                            DispenserNo: {type: 'number', editable: false},
                            FuelPositionNo: {type: 'number', editable: false},
                            ProductDescription: {type: 'string', editable: false},
                            RtlPrice: {type: "number", format: "{n3}", editable: true},
                            EndAmount: {type: "number", editable: true},
                            NetAmount: {type: "number", editable: true},
                            EndUnits: {type: "number", editable: true},
                            NetUnits: {type: "number", editable: true},
                            CalcPrice: {type: "number", editable: false}
                        }
                    }
                },
                data: $scope.appInfo.paperwork.meters,
                batch: true
            });

            $scope.metersGridOptions = {
                dataSource: $scope.metersGridDS,
                columns: [
                    {
                        title: localStrings.getLocalizedString("Fuel"),
                        columns: [
                            {
                                field: "DispenserNo",
                                title: localStrings.getLocalizedString("Disp"),
                                width: 40,
                                attributes: {"data-field": "DispenserNo", "class": "tableCellDisabled"}
                            },
                            {
                                field: "FuelPositionNo",
                                title: localStrings.getLocalizedString("Pos"),
                                width: 40,
                                attributes: {"data-field": "FuelPosition", "class": "tableCellDisabled"}
                            },
                            {
                                field: "ProductDescription",
                                title: localStrings.getLocalizedString("Description"),
                                attributes: {
                                    "data-field": "ProductDescription",
                                    "class": "tableCellDisabled description"
                                }
                            }
                        ]
                    },
                    {
                        field: "RtlPrice",
                        width: 80,
                        editor: gridColEditorInput,
                        template: gridColNumber("RtlPrice", "n3"),
                        headerTemplate: "<span style='white-space: initial'>" + localStrings.getLocalizedString("RetailPrice") +"</span>",
                        attributes: {"data-field": "RtlPrice", "class": "k-editable-cell", "required": "true"},
                        hidden: !$scope.appInfo.paperwork.showRetailPrice
                    },
                    {
                        title: localStrings.getLocalizedString("Dollars"),
                        columns: [
                            {
                                field: "EndAmount",
                                title: localStrings.getLocalizedString("EndingMeter"),
                                width: 110,
                                editor: gridColEditorInput,
                                template: gridColNumber("EndAmount", "n2"),
                                attributes: {"data-field": "EndAmount", "class": "k-editable-cell", "required": "true"},
                                hidden: !$scope.appInfo.paperwork.showEndingDollar
                            },
                            {
                                field: "NetAmount",
                                title: localStrings.getLocalizedString("Net"),
                                width: 110,
                                editor: gridColEditorInput,
                                template: gridColNumber("NetAmount", "n2"),
                                attributes: {"data-field": "NetAmount", "class": "k-editable-cell", "required": "true"},
                                hidden: !$scope.appInfo.paperwork.showNetDollars
                            }
                        ]
                    },
                    {
                        title: localStrings.getLocalizedString("Units"),
                        columns: [
                            {
                                field: "EndUnits",
                                title: localStrings.getLocalizedString("EndingMeter"),
                                width: 110,
                                editor: gridColEditorInput,
                                template: gridColNumber("EndUnits", "n3"),
                                attributes: {"data-field": "EndUnits", "class": "k-editable-cell", "required": "true"},
                                hidden: !$scope.appInfo.paperwork.showEndingUnits
                            },
                            {
                                field: "NetUnits",
                                title: localStrings.getLocalizedString("Net"),
                                width: 110,
                                editor: gridColEditorInput,
                                template: gridColNumber("NetUnits", "n3"),
                                attributes: {"data-field": "NetUnits", "class": "k-editable-cell", "required": "true"},
                                hidden: !$scope.appInfo.paperwork.showNetUnits
                            }
                        ]
                    },
                    {
                        field: "CalcPrice",
                        width: 110,
                        editor: gridColEditorInput,
                        template: gridColNumber("CalcPrice", "n3"),
                        headerTemplate: "<span style='white-space: initial'>" + localStrings.getLocalizedString("CalcAvgPrice") + "</span>",
                        attributes: {"data-field": "CalcPrice", "class": "tableCellDisabled"},
                        hidden: !$scope.appInfo.paperwork.showAvgPrice
                    },
                    {
                        template: "<i class='fa fa-pencil paperwork-table-button' ng-click='openBeginMeter(dataItem)'></i>",
                        headerTemplate: "<span style='white-space: initial'>" + localStrings.getLocalizedString("BeginMeter") + "</span>",
                        width: 60,
                        attributes: {"data-field": "BeginMeterBtn"},
                        hidden: !$scope.appInfo.paperwork.showBeginMeters
                    },
                    {
                        template: "<i class='fa fa-pencil paperwork-table-button' ng-click='openPumpTest(dataItem)'></i>",
                        headerTemplate: "<span style='white-space: initial'>" + localStrings.getLocalizedString("PumpTest") + "</span>",
                        width: 60,
                        attributes: {"data-field": "PumpTestBtn"}
                    }
                ],
                pageable: false,
                sortable: false,
                editable: true,
                navigatable: true,
                scrollable: false,
                dataBinding: function (e) {
                    var current = e.sender.current() || [];
                    if (current[0]) {
                        cellIndex = current.index();
                        rowIndex = current.parent().index();
                    }
                },
                dataBound: function (e)
                {
                    $scope.setupMetersRows();
                },
                edit: function (e)
                {
                    var $input = e.container.find("input");
                    var fieldName = $input.attr("name");

                    // alternative (if you don't have the name attribute in your editable):
                    // var columnIndex = this.cellIndex(e.container);
                    // var fieldName = this.thead.find("th").eq(columnIndex).data("field");

                    if (!meterGridColIsEditable(fieldName, e.model))
                    {
                        $input.prop('readonly', true);
                        //this.closeCell(); // prevent editing
                    } else
                    {
                        $input.select();
                        $input.addClass('k-cell-state-selected');
                    }

                    var grid = $("#metersGrid").data("kendoGrid");
                    var currentCell = $(grid.tbody.children().find(".k-edit-cell"));

                    grid.current(currentCell);
                }
            };
        };

        function meterGridColIsEditable(fieldName, model)
        {
            if (fieldName === "RtlPrice") {
                if (!$scope.appInfo.paperwork.showRetailPrice)
                {
                    return false;
                }
            }

            if (fieldName === "EndAmount")
            {
                if (model.DollarsEndingState !== 1)
                {
                    return false;
                }
            }

            if (fieldName === "NetAmount")
            {
                if (model.DollarsNetState !== 1)
                {
                    return false;
                }
            }

            if (fieldName === "EndUnits")
            {
                if (model.UnitsEndingState !== 1)
                {
                    return false;
                }
            }

            if (fieldName === "NetUnits")
            {
                if (model.UnitsNetState !== 1)
                {
                    return false;
                }
            }

            if (fieldName === "BeginMeterBtn")
            {
                if (model.FuelPositionUnitReading !== 1 && model.FuelPositionDollarReading !== 1)
                {
                    return false;
                }
            }

            return true; // default to editable
        }

        function onGridKeydown(e)
        {
            if (e.keyCode === kendo.keys.TAB)
            {
                $scope.focusNextEditableCell();
            }
        }

        $scope.gridInputCellOnBlur = function (dataItem, fieldName)
        {
            if (fieldName === "RtlPrice")
            {
                $scope.retailChange(dataItem);
            }

            if (fieldName === "EndAmount")
            {
                $scope.endAmountChange(dataItem);
            }

            if (fieldName === "NetAmount")
            {
                $scope.netAmountChange(dataItem);
            }

            if (fieldName === "EndUnits")
            {
                $scope.endUnitsChange(dataItem);
            }

            if (fieldName === "NetUnits")
            {
                $scope.netUnitsChange(dataItem);
            }
        };

        $scope.setupMetersRows = function ()
        {

            $("#metersGrid tr").each(function (index, row)
            {

                if (index > 1)
                { // skip header rows
                    var dataItem = $("#metersGrid").data("kendoGrid").dataItem(this);

                    var $row = $(row);
                    var $rowColumns = $row.find('td');

                    $rowColumns.each(function (index, col)
                    {
                        var $col = $(col);
                        var fieldName = $col.attr('data-field');
                        if (!meterGridColIsEditable(fieldName, dataItem))
                        {
                            $col.addClass("tableCellDisabled");
                            $col.removeClass("k-editable-cell");

                            if (fieldName === "BeginMeterBtn")
                            {
                                $col.find('i').hide();
                            }
                        }

                        //setup checkbox columns
                        /* if (fieldName === "BeginMeterBtn") {
                         $col.on("click", "input.elecChkBox", function (e) {
                         $scope.openBeginMeter(dataItem)
                         });
                         }*/

                        $scope.setupGridColumnValidationNotificationUI($col);
                    });

                }
            });
        };

        $scope.setupMetersGridColumns = function ()
        {

            var showDispenserNo = $scope.appInfo.paperwork.meters.some(function (meter)
            {
                return meter.DispenserNo > 0;
            });

            if (!showDispenserNo)
            {
                $scope.kendo.metersGrid.hideColumn("DispenserNo");
            }

            if (!$scope.appInfo.paperwork.showRetailPrice)
            {
                $scope.kendo.metersGrid.hideColumn("RtlPrice");
            }
        };

        $scope.$on("kendoWidgetCreated", function (event, widget)
        {

            var grid = $("#metersGrid").data("kendoGrid");

            // the event is emitted for every widget; if we have multiple
            // widgets in this controller, we need to check that the event
            // is for the one we're interested in.

            if (widget === $scope.kendo.metersGrid)
            {
                grid.dataSource.data($scope.appInfo.paperwork.meters);
                $("#metersGrid").find("table").on("keydown", onGridKeydown);
                $scope.setupMetersGridColumns();

                $scope.calculatePageTotals();
            }
        });

        $scope.setupGridColumnValidationNotificationUI = function ($col)
        {

            var fieldName = $col.attr('data-field');

            $col.qtip({
                id: "qtip-" + fieldName,
                content: {
                    text: ""
                },
                position: {
                    adjust: {
                        x: -10, y: -10
                    }
                },
                style: {
                    classes: "toolTipStyle"
                }
            });

            $col.qtip('disable', true);

        };

        $scope.processGridColumnValidationNotification = function ($row, fieldName, errorMessages, warningMessages)
        {

            var idx = 0;

            var hasErrors = (errorMessages.length > 0);
            var hasWarnings = (warningMessages.length > 0);

            var $col = $row.find("[data-field='" + fieldName + "']");

            //clear classes
            $col.removeClass("td-error");
            $col.removeClass("td-warning");

            if (hasErrors || hasWarnings)
            {

                var messageList = $("<div></div>");

                if (hasErrors)
                {
                    var errorList = $("<div style='padding-bottom: 2px'>" + localStrings.getLocalizedString("Error") + " :</div>");
                    for (idx = 0; idx < errorMessages.length; idx++)
                    {
                        errorList.append("<li style='padding: 1px 0px 1px 7px'>" + errorMessages[idx] + "</li>");
                    }

                    messageList.append(errorList);
                }

                if (hasWarnings)
                {
                    var warningList = $("<div style='padding - bottom: 2px'>" + localStrings.getLocalizedString("Warning") + " :</div>");
                    for (idx = 0; idx < warningMessages.length; idx++)
                    {
                        warningList.append("<li style='padding: 1px 0px 1px 7px'>" + warningMessages[idx] + "</li>");
                    }

                    messageList.append(warningList);
                }

                $col.qtip('option', 'content.text', messageList);

                if (hasErrors)
                {
                    $col.addClass("td-error");
                    $col.qtip('option', 'style.classes', 'toolTipStyle-error');
                } else
                {
                    $col.addClass("td-warning");
                    $col.qtip('option', 'style.classes', 'toolTipStyle-warning');
                }

                $col.qtip('disable', false);
            }
            else
            {
                $col.qtip('disable', true);
            }
        };

        var meterRowValidation =
        {
            validator: function (meter, parms)
            {
                var isValid = true;

                var $row = $("#metersGrid").data("kendoGrid").tbody.find("tr[data-uid='" + meter.uid + "']");
                
                // retail price validation
                if ($scope.appInfo.paperwork.showRetailPrice)
                {

                    var errorRtlPrice = [], warningRtlPrice = [];
                    
                    // retail price errors
                    if ((angular.isUndefinedOrNullOrEmpty(meter.RtlPrice) || meter.RtlPrice === 0) && (meter.NetAmount > 0 && meter.NetUnits > 0))
                    {
                        isValid = false;

                        errorRtlPrice.push(localStrings.getLocalizedString("GTZeroError"));
                    }

                    // retail price warnings
                    if ($scope.appInfo.paperwork.showRetailPrice && !angular.isUndefinedOrNullOrEmpty(meter.CalcPrice) && !angular.isUndefinedOrNullOrEmpty(meter.PriorCalcPrice))
                    {

                        var calcTotalSales = (meter.RtlPrice * meter.NetUnits);

                        if ((Math.abs(calcTotalSales - meter.NetAmount) > $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance) && calcTotalSales > 0 && meter.NetAmount > 0 && $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance > 0)
                        {
                            warningRtlPrice.push(localStrings.getLocalizedString("CapturedTotalOutside"));
                        }

                        if ((Math.abs(meter.RtlPrice - meter.CalcPrice) > $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance) && meter.RtlPrice > 0 && meter.CalcPrice > 0 && $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance > 0)
                        {
                            warningRtlPrice.push(localStrings.getLocalizedString("CapturedRetailPrice"));
                        }
                    }

                    $scope.processGridColumnValidationNotification($row, 'RtlPrice', errorRtlPrice, warningRtlPrice);
                }

                //end units validation
                var errorEndUnits = [], warningEndUnits = [];
                if (meter.EndUnits > 0)
                {
                    if (meter.EndUnits < meter.BeginUnits && $scope.appInfo.paperwork.showBeginMeters)
                    {
                        warningEndUnits.push(localStrings.getLocalizedString("UnitsRolled"));
                    }
                    if (meter.EndUnits >= $scope.MaxUnits) {
                        isValid = false;
                        errorEndUnits.push(localStrings.getLocalizedString("UnitsMaxExceeded"));
                    }
                }
                $scope.processGridColumnValidationNotification($row, 'EndUnits', errorEndUnits, warningEndUnits);

                //end amount validation
                var errorEndAmount = [], warningEndAmount = [];
                if (meter.EndAmount > 0)
                {
                    if (meter.EndAmount < meter.BeginAmount && $scope.appInfo.paperwork.showBeginMeters)
                    {
                        warningEndAmount.push(localStrings.getLocalizedString("UnitsRolled"));
                    }
                    if (meter.EndAmount >= $scope.MaxUnits) {
                        isValid = false;
                        errorEndAmount.push(localStrings.getLocalizedString("DollarsMaxExceeded"));
                    }
                }
                $scope.processGridColumnValidationNotification($row, 'EndAmount', errorEndAmount, warningEndAmount);

                //calc price validation
                var errorCalcPrice = [], warningCalcPrice = [];
                if (!angular.isUndefinedOrNullOrEmpty(meter.CalcPrice) && !angular.isUndefinedOrNullOrEmpty(meter.PriorCalcPrice))
                {
                    if ((Math.abs(meter.CalcPrice - meter.PriorCalcPrice) > $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance) && meter.CalcPrice > 0 && meter.PriorCalcPrice > 0 && $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance > 0)
                    {
                        warningCalcPrice.push(localStrings.getLocalizedString("CalculatedPriceOutside"));
                    }
                }
                $scope.processGridColumnValidationNotification($row, 'CalcPrice', errorCalcPrice, warningCalcPrice);

                return isValid;
            }
        };

        $scope.validateMeters = function ()
        {

            var isValid = true;

            var metersGrid = $("#metersGrid").data("kendoGrid");

            angular.forEach(metersGrid.dataSource.data(), function (meter)
            {
                isValid = isValid && meterRowValidation.validator(meter);
            });

            return isValid;
        };

        var newPWBatchLoadedListener = $rootScope.$on('event:NewPaperworkBatchLoaded', function (event, data)
        {
            if ($scope.locationInfo.sideMenuData.paperwork.meters)
                $scope.loadMeters();
            else if ($scope.locationInfo.sideMenuData.paperwork.tanks)
            {
                if ($scope.appInfo.paperwork.viewOnly)
                    $scope.goTo("/Paperwork/ViewPaperworkTanks/" + $scope.appInfo.paperwork.paperworkKey);
                else
                    $scope.goTo("/Paperwork/EnterPaperworkTanks/" + $scope.appInfo.paperwork.paperworkKey);
            }
            else if ($scope.locationInfo.sideMenuData.paperwork.deliveries)
            {
                if ($scope.appInfo.paperwork.viewOnly)
                    $scope.goTo("/Paperwork/ViewPaperworkDeliveries/" + $scope.appInfo.paperwork.paperworkKey);
                else
                    $scope.goTo("/Paperwork/EnterPaperworkDeliveries/" + $scope.appInfo.paperwork.paperworkKey);
            }
            else
            {
                if ($scope.appInfo.paperwork.viewOnly)
                    $scope.goTo("/Paperwork/ViewPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
                else
                    $scope.goTo("/Paperwork/EnterPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
            }
        });

        // $scope $destroy
        $scope.$on('$destroy', newPWBatchLoadedListener);

        $scope.loadMeters = function ()
        {
            $scope = this;

            angular.forEach($scope.appInfo.paperwork.context.paperworkSites, function (site, index)
            {
                if (site.SiteKey === $scope.appInfo.paperwork.batch.SiteKey)
                    $scope.appInfo.paperwork.context.selectedSiteObj = site;
            });
            PaperworkMetersService.loadMetersForEntry(
                $scope.appInfo.paperwork.batch.Key,
                $scope.appInfo.paperwork.context.selectedSiteObj.PCKey,
                $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true)
            ).then(function (data)
                {
//                    if(data.count == 0){
                    //TODO: No meters, do something...
//
//                  }
                    $scope.appInfo.paperwork.meters = data.PaperworkMeters;
                    $scope.appInfo.paperwork.priceToleranceAmount = data.PriceToleranceAmount;
                    $scope.appInfo.paperwork.showRetailPrice = data.ShowRetailPrice;
                    $scope.appInfo.paperwork.showEndingDollar = data.ShowEndingDollar;
                    $scope.appInfo.paperwork.showNetDollars = data.ShowNetDollars;
                    $scope.appInfo.paperwork.showEndingUnits = data.ShowEndingUnits;
                    $scope.appInfo.paperwork.showNetUnits = data.ShowNetUnits;
                    $scope.appInfo.paperwork.showAvgPrice = data.ShowAvgPrice;
                    $scope.appInfo.paperwork.showBeginMeters = data.ShowBeginMeters;

                    $scope.initializeMetersGrid();

                    $scope.metersLoaded = true;

                    if ($scope.appInfo.paperwork.meters.length > 0) {
                        $scope.metersEditable = !(($scope.appInfo.paperwork.context.lockPaperworkAfterDru && $scope.appInfo.paperwork.meters[0].DRUPosted) || $scope.appInfo.paperwork.meters[0].Status === 1);
                    }

                    //If entering a new paperwork batch create the default header form in the database
                    //if (!$scope.appInfo.paperwork.viewOnly && $scope.metersEditable && $scope.appInfo.paperwork.form.PaperworkBatchKey === 0) {
                    //    var paperworkMeterHeader =
                    //    {
                    //        Key: $scope.appInfo.paperwork.meters[0].FuelMtrKey,
                    //        SiteKey: $scope.appInfo.paperwork.batch.SiteKey,
                    //        SiteDesc: $scope.appInfo.paperwork.context.selectedSiteObj.SiteDesc,
                    //        ProfCtrKey: $scope.appInfo.paperwork.context.selectedSiteObj.PCKey,
                    //        PaperworkBatchKey: $scope.appInfo.paperwork.batch.Key,
                    //        PaperworkDate: $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true),
                    //        UserName: $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                    //        SourceKey: 0,
                    //        Ts: $scope.appInfo.paperwork.meters[0].HeaderTimeStamp,
                    //        AppKey: $scope.locationInfo.sideMenuData.paperwork.meters.Key
                    //    };

                        //Pass in default meters data since the grid has not yet been modified
                        //PaperworkMetersService.savePaperworkMeters(paperworkMeterHeader, $scope.appInfo.paperwork.meters, true);
                    //}

                    //$scope.metersLoaded = true;
                });
        };

        var navToTanks = $rootScope.$on('event:NavigateToTanks', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.next('/Paperwork/ViewPaperworkTanks/');
            else
                $scope.next('/Paperwork/EnterPaperworkTanks/');
        });

        $scope.$on('$destroy', navToTanks);

        var navToDeliveries = $rootScope.$on('event:NavigateToDeliveries', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.next('/Paperwork/ViewPaperworkDeliveries/');
            else
                $scope.next('/Paperwork/EnterPaperworkDeliveries/');
        });

        $scope.$on('$destroy', navToDeliveries);

        var navToForms = $rootScope.$on('event:NavigateToForms', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.next('/Paperwork/ViewPaperworkDealerForms/');
            else
                $scope.next('/Paperwork/EnterPaperworkDealerForms/');
        });

        $scope.$on('$destroy', navToForms);

        $scope.next = function (path)
        {
            if (!$scope.appInfo.paperwork.viewOnly && $scope.metersEditable && $scope.metersGridDS.hasChanges())
            {
                $scope.saveMeters(path + $scope.appInfo.paperwork.paperworkKey, false, false);
            } else {
                $scope.goTo(path + $scope.appInfo.paperwork.paperworkKey);
            }

        };

        $scope.previous = function ()
        {
        };

        $scope.close = function ()
        {
            if (!$scope.appInfo.paperwork.viewOnly && $scope.metersEditable)
            {
                $scope.saveMeters("", false, false);
            } else
            {
                $scope.goTo("/Paperwork/EnterPaperwork/");
            }

        };

        $scope.submit = function ()
        {
            $scope.saveMeters("", true, false);
        };

        $scope.submitPaperwork = function (submit)
        {
            var fuelMetersAppInfo = $scope.appInfo.paperwork.apps[0];

            //If we have fuelMeters and fuelMeters are required, but not saved; then throw error
            if (fuelMetersAppInfo.HasData === 0 && fuelMetersAppInfo.IsRequired !== 0)
            {
                $timeout(function () {
                    $scope.modalifier.showSavedError();
                }, 100);
                return;
            }

            var submitPaperworkCmdParms =
            {
                SiteKey: $scope.appInfo.paperwork.batch.SiteKey,
                PaperworkDate: $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true),
                EmailAddress: $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                SubmitPaperwork: submit,
                PreviouslySubmitted: $scope.appInfo.paperwork.batch.HasBeenSubmitted
            };
            PaperworkService.SubmitPaperwork(submitPaperworkCmdParms).then(function (data)
            {
                if (submit)
                    $scope.goTo("Paperwork/InventorySummary/" + $scope.appInfo.paperwork.batch.SiteKey + "/" + $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true));
            });
        };

        $scope.saveMeters = function (path, submit, close)
        {
            if (!$scope.validateMeters()) {
                return;
            }

            $scope.resetDirtyFlags($scope.metersForm);

            var paperworkMeterHeader =
            {
                Key: $scope.appInfo.paperwork.meters[0].FuelMtrKey,
                SiteKey: $scope.appInfo.paperwork.batch.SiteKey,
                SiteDesc: $scope.appInfo.paperwork.context.selectedSiteObj.SiteDesc,
                ProfCtrKey: $scope.appInfo.paperwork.context.selectedSiteObj.PCKey,
                PaperworkBatchKey: $scope.appInfo.paperwork.batch.Key,
                PaperworkDate: $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true),
                UserName: $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                SourceKey: 0,
                Ts: $scope.appInfo.paperwork.meters[0].HeaderTimeStamp,
                AppKey: $scope.locationInfo.sideMenuData.paperwork.meters.Key
            };

            angular.copy($scope.metersGridDS.data().toJSON(), $scope.appInfo.paperwork.meters);

            // Call these functions to translate the french numeric value to a actual number value.
            PaperworkMetersService.updateMeters($scope.appInfo.paperwork.meters);
            
            PaperworkMetersService.savePaperworkMeters(paperworkMeterHeader, $scope.appInfo.paperwork.meters).then(function (data) {
                var grid = $("#metersGrid").data("kendoGrid");
                $scope.appInfo.paperwork.meters = data.PaperworkMeters;
                if (grid) {
                    grid.dataSource.data(data.PaperworkMeters);

                    $scope.validateMeters(); // call validate to re add any warnings after save
                }

                $scope.appInfo.paperwork.apps[0].HasData = -1;

                if (submit) {
                    $scope.submitPaperwork(true);
                    return;
                }
                if (close) {
                    $scope.submitPaperwork(false);
                    return;
                }
                if (path.length > 0)
                    $scope.goTo(path);
            });
        };

        $scope.retailChange = function (dataItem)
        {
            PaperworkMetersService.netAmountCalc(dataItem);
            PaperworkMetersService.calcPerUnit(dataItem,
                $scope.appInfo.paperwork.priceToleranceAmount,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceToRetail,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceOverDay);
            $scope.calculatePageTotals();
        };

        $scope.endAmountChange = function (dataItem)
        {
            PaperworkMetersService.calculateNetAmountFromEnd(dataItem);
            PaperworkMetersService.calcPerUnit(dataItem,
                $scope.appInfo.paperwork.priceToleranceAmount,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceToRetail,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceOverDay,
                $scope.appInfo.paperwork.showRetailPrice);
            $scope.calculatePageTotals();
        };

        $scope.netAmountChange = function (dataItem)
        {
            PaperworkMetersService.calcPerUnit(dataItem,
                $scope.appInfo.paperwork.priceToleranceAmount,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceToRetail,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceOverDay,
                $scope.appInfo.paperwork.showRetailPrice);
            $scope.calculatePageTotals();
        };

        $scope.netUnitsChange = function (dataItem)
        {
            PaperworkMetersService.netAmountCalc(dataItem);
            PaperworkMetersService.calcPerUnit(dataItem,
                $scope.appInfo.paperwork.priceToleranceAmount,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceToRetail,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceOverDay,
                $scope.appInfo.paperwork.showRetailPrice);
            $scope.calculatePageTotals();
        };

        $scope.endUnitsChange = function (dataItem)
        {
            PaperworkMetersService.calculateNetUnitsFromEnd(dataItem);
            PaperworkMetersService.netAmountCalc(dataItem);
            PaperworkMetersService.calcPerUnit(dataItem,
                $scope.appInfo.paperwork.priceToleranceAmount,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceToRetail,
                $scope.appInfo.paperwork.siteInfo.CheckAveragePriceOverDay,
                $scope.appInfo.paperwork.showRetailPrice);
            $scope.calculatePageTotals();
        };

        $scope.calculatePageTotals = function ()
        {
            var metersGrid = $("#metersGrid").data("kendoGrid");

            $scope.appInfo.paperwork.totalAmount = PaperworkMetersService.calculatePageAmountTotals(metersGrid.dataSource.data());
            $scope.appInfo.paperwork.totalUnits = PaperworkMetersService.calculatePageUnitTotals(metersGrid.dataSource.data());

            // update the grid and make sure it has the latest data
            metersGrid.dataSource.data(metersGrid.dataSource.data());
        };

        $scope.focusNextEditableCell = function () {
            var metersGrid = $("#metersGrid").data("kendoGrid");

            if (!isNaN(cellIndex)) {
                var current = metersGrid.current(metersGrid.tbody.children().eq(rowIndex).children().eq(cellIndex));

                rowIndex = cellIndex = null;
                
                if (current.hasClass("k-editable-cell"))
                {
                    var nextCell = current.nextAll(".k-editable-cell");
                    if (!nextCell[0])
                    {
                        //search the next row
                        nextCell = current.parent().next().children(".k-editable-cell:first");
                    }
                    metersGrid.current(nextCell);
                    metersGrid.editCell(nextCell[0]);
                }
            }
        };

        $scope.openBeginMeter = function (dataItem)
        {
            
            $scope.modalInfo.dataItem = dataItem;
            $scope.modalInfo.beginAmount = dataItem.BeginAmount;
            $scope.modalInfo.beginUnits = dataItem.BeginUnits;
            $scope.modalInfo.useBeginUnits = (dataItem.FuelPositionUnitReading === 1);
            $scope.modalInfo.useBeginAmount = (dataItem.FuelPositionDollarReading === 1);
            $scope.modalInfo.type = 0;
            $scope.modalInfo.header = localStrings.getLocalizedString("BeginMeter");
            $scope.modalInfo.shown = true;
        };

        $scope.openPumpTest = function (dataItem)
        {
                $scope.modalInfo.dataItem = dataItem;
                $scope.modalInfo.beginAmount = dataItem.TestAmount;
                $scope.modalInfo.beginUnits = dataItem.TestUnits;
                $scope.modalInfo.useBeginUnits = true;
                $scope.modalInfo.useBeginAmount = true;
                $scope.modalInfo.type = 1;
                $scope.modalInfo.header = localStrings.getLocalizedString("PumpTest");
                $scope.modalInfo.shown = true;
        };

        $scope.saveModalValues = function ()
        {
            if ($scope.modalInfo.beginAmount < 0 || $scope.modalInfo.beginUnits < 0)
            {
                $scope.notifier.show(localStrings.getLocalizedString("GTZeroError2"), 'error');
                return;
            }
            if ($scope.modalInfo.beginUnits >= $scope.MaxUnits) {
                $scope.notifier.show(localStrings.getLocalizedString("UnitsMaxExceeded"), 'error');
                return;
            }
            if ($scope.modalInfo.beginAmount >= $scope.MaxUnits) {
                $scope.notifier.show(localStrings.getLocalizedString("DollarsMaxExceeded"), 'error');
                return;
            }

            switch ($scope.modalInfo.type)
            {
                case 0:
                    $scope.modalInfo.dataItem.BeginAmountOverride =
                        ($scope.modalInfo.dataItem.BeginAmount === $scope.modalInfo.beginAmount);
                    $scope.modalInfo.dataItem.BeginAmount = $scope.modalInfo.beginAmount;
                    $scope.modalInfo.dataItem.BeginUnitsOverride =
                        ($scope.modalInfo.dataItem.BeginUnits === $scope.modalInfo.beginUnits);
                    $scope.modalInfo.dataItem.BeginUnits = $scope.modalInfo.beginUnits;
                    $scope.endAmountChange($scope.modalInfo.dataItem);
                    $scope.endUnitsChange($scope.modalInfo.dataItem);
                    break;

                case 1:
                    $scope.modalInfo.dataItem.TestAmount = $scope.modalInfo.beginAmount;
                    $scope.modalInfo.dataItem.TestUnits = $scope.modalInfo.beginUnits;
                    $scope.calculatePageTotals();
                    break;
            }

            $scope.modalInfo = {};
            $scope.modalInfo.shown = false;
        };
    })
    .controller('enterPaperworkTanksCtrl', function ($scope, $rootScope, $routeParams, PaperworkService, $filter, $timeout, PaperworkTanksService,
                                                        $location, WebFunctions, validatorSvc, templates, localStrings, globalizationSvc)
    {
        'use strict';

        $scope.kendo = {};

        $scope.appInfo.paperwork.paperworkKey = 0;
            $scope.modalInfo = {};
            $scope.tanksLoaded = false;
            $scope.postedReadingsExist = false;

            // controller destructor
            $scope.$on("$destroy", function handler()
            {
                $rootScope.scopesToValidate = [];
            });

            $scope.initialize = function ()
            {
                if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
                {
                    $scope.goTo("/Home");
                }       

                $scope.appInfo = $scope.appInfo || {};

                $scope.appInfo.paperwork.viewOnly = $location.$$path.indexOf('/ViewPaperworkTanks/') > -1;
                if ($routeParams.paperworkKey)
                {
                    $scope.appInfo.paperwork.paperworkKey = $routeParams.paperworkKey;
                }
                $scope.appInfo.paperwork.NewOSComments = '';
                $scope.locationInfo.menuLocation = 3;
                $scope.locationInfo.subMenuLocation = 30;
                if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
                $scope.locationInfo.sideMenu = templates.getTemplate("PaperworkSideMenu");
                $scope.locationInfo.sideMenuData.subMenu = '1';
                $scope.appInfo.paperwork.batch = {};
                $scope.appInfo.paperwork.tanks = [];
                $scope.appInfo.paperwork.postedTanks = [];

                if (!$scope.appInfo.paperwork.context)
                {
                    PaperworkService.getPaperworkContext($scope.appInfo.webUser.CurrentAuthorization.CustContKey, true).then(
                        function (data)
                        {
                            $scope.appInfo.paperwork.context = {};
                            $scope.appInfo.paperwork.context.paperworkSites = data.PaperworkSites;
                            $scope.appInfo.paperwork.context.selectedSite = data.SelectedSiteKey;
                            $scope.appInfo.paperwork.context.lockPaperworkAfterDru = data.LockPaperworkAfterDru;

                            $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance = data.CalculatedPriceToPriorDaysPriceVariance;
                            $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance = data.CalculatedPriceToCapturedRetailPriceVariance;
                            $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance = data.NetSalesDollarsToCapturedRetailPriceNetUnitsVariance;

                            $scope.locationInfo.showEnterPaperworkMenuItem = $scope.appInfo.paperwork.context.paperworkSites[0] ?
                                $scope.appInfo.paperwork.context.paperworkSites[0].CanEnterPaperwork :
                                false;

                            if (!$scope.locationInfo.showEnterPaperworkMenuItem)
                            {
                                $scope.locationInfo.sideMenuData.subMenu = '2';
                                $location.path("/Paperwork/ReviewPaperwork");
                            }
                        }
                    );
                }

                if ($scope.appInfo.paperwork.paperworkKey > 0)
                {
                    //We are loading a known existing paperwork batch
                    PaperworkService.OpenPaperworkBatchByKey(
                        $scope.appInfo.paperwork.paperworkKey,
                        $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                        $scope.appInfo.webUser.Id,
                        $scope.appInfo.paperwork.viewOnly
                    ).then(function (data)
                    {
                        $scope.appInfo.paperwork.batch = data.PaperworkBatch.PaperworkBatch;
                        $scope.appInfo.paperwork.apps = data.PaperworkBatch.PaperworkApps;
                        $scope.appInfo.paperwork.form = data.PaperworkBatch.PaperworkForm;
                        $scope.appInfo.paperwork.siteInfo = data.PaperworkBatch.PaperworkSiteInfo;

                        $rootScope.$broadcast('event:PaperworkLoaded', $scope.appInfo.paperwork.viewOnly, $scope.appInfo.paperwork.paperworkKey);

                        var nextApp = true;
                        angular.forEach($scope.appInfo.paperwork.apps, function (app, index)
                        {
                            if (app.MenuCode === 'DREGS')
                            {
                                $scope.loadTanks(false);
                                nextApp = false;
                            }
                        });
                        if (nextApp)
                        {
                            if ($scope.appInfo.paperwork.viewOnly)
                                $scope.goTo("/Paperwork/ViewPaperworkDeliveries/" + $scope.appInfo.paperwork.paperworkKey);
                            else
                                $scope.goTo("/Paperwork/EnterPaperworkDeliveries/" + $scope.appInfo.paperwork.paperworkKey);
                        }
                    }, function ()
                    {
                        $location.path("/Paperwork/ReviewPaperwork");
                    });
                } else
                {
                    //todo: goback...
                }
                $scope.locationInfo.sideMenuData.pwSubMenu = '2';
                $scope.appInfo.paperwork.tankIndex = 0;
            };

            var newPWBatchLoadedListener = $rootScope.$on('event:NewPaperworkBatchLoaded', function (event, data)
            {
                if ($scope.locationInfo.sideMenuData.paperwork.tanks)
                    $scope.loadTanks(false);
                else
                {
                    if ($scope.appInfo.paperwork.viewOnly)
                        $scope.goTo("/Paperwork/ViewPaperworkDeliveries/" + $scope.appInfo.paperwork.paperworkKey);
                    else
                        $scope.goTo("/Paperwork/EnterPaperworkDeliveries/" + $scope.appInfo.paperwork.paperworkKey);
                }
            });

            // $scope $destroy
            $scope.$on('$destroy', newPWBatchLoadedListener);

            var gridColEditorInput = function (container, options)
            {

                var fieldName = "'" + options.field + "'";

                var required = $(container).attr('required'); // field is required

                // For some browsers, `attr` is undefined; for others,
                // `attr` is false.  Check for both.
                if (typeof required !== typeof undefined && required !== false)
                {
                    $('<input type="text" name="' + options.field + '" data-bind="value:' + options.field + '" ng-blur="gridInputCellOnBlur(dataItem, ' + fieldName + ')" required data-Required-msg="' + localStrings.getLocalizedString("Required") + '"/>')
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 2,
                            format: "#",
                            min: 0,
                            spinners: false
                        });
                } else
                {
                    $('<input type="text" name="' + options.field + '" data-bind="value:' + options.field + '" ng-blur="gridInputCellOnBlur(dataItem, ' + fieldName + ')" />')
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 2,
                            format: "#",
                            min: 0,
                            spinners: false
                        });
                }

            };

            var gridColNumber = function (field, format)
            {
                return '<label>#= kendo.toString(' + field + ', "' + format + '", "' + globalizationSvc.getLocale() + '")#</label>';
            };

            var partialUnitsEditor = function (container, options)
            {
                $("<input name='" + options.field + "' data-text-field='Key' data-value-field='Value' data-bind='value:" + options.field + "' required='required' data-Required-msg='Required' />")

                    .appendTo(container)
                    .kendoComboBox({
                        id: "partialUnits",
                        dataSource: {
                            data: $scope.appInfo.paperwork.partialUnits
                        },
                        filter: "contains",
                        dataTextField: localStrings.getLocalizedString("Key"),
                        dataValueField: "Value",
                    change: function (e)
                    {

                        var selectionElement = $(e.sender.element[0]).parent().find(".k-dropdown-wrap");

                        // check if valid
                        if ($(e.sender.element[0]).hasClass("k-invalid"))
                        {
                            selectionElement.addClass("k-selection-invalid");
                        } else
                        {
                            selectionElement.removeClass("k-selection-invalid");

                            var grid = $("#tanksGrid").data("kendoGrid");

                            var selectedRow = $(".k-grid-edit-row");
                            //Get the item from the grid
                            var selectedRowData = grid.dataItem(selectedRow);

                            if (options.field === "PartialUnits")
                            {
                                $scope.calculateTankValues(selectedRowData);
                            } else
                            {
                                $scope.calculateWaterVolumeValues(selectedRowData);
                            }
                        }

                        if (this.value() && this.selectedIndex === -1)
                        {
                            this.value('');
                            this._filterSource({
                                value: "",
                                field: this.options.dataTextField,
                                operator: "contains"
                            });
                            return;
                        }
                    },
                    select: function (e)
                    {

                        var dataItem = this.dataItem(e.item.index());
                    }
                });

            $('<span class="k-invalid-msg" data-for="' + options.field + '"></span>').appendTo(container);

        };

        function getPartialDescFromValue(dataItem)
        {
            return $scope.appInfo.paperwork.partialUnits.find({Value: dataItem.PartialUnits}).Key;
        }

        $scope.initializeTanksGrid = function ()
        {

            $scope.tanksGridDS = new kendo.data.DataSource({
                schema: {
                    model: {
                        fields: {
                            TankNo: {type: "number", editable: false},
                            ProductId: {type: "string", editable: false},
                            ProductDescription: {type: "string", editable: false},
                            NoReading: {type: "boolean"},
                            LargeUnits: {type: "number"},
                            SmallUnits: {type: "number"},
                            PartialUnits: {type: "number"},
                            Quantity: {type: "number"},
                            WaterSmallUnits: {type: "number"},
                            WaterPartialUnits: {type: "number"},
                            WaterQuantity: {type: "number"},
                            Temperature: {type: "number"},
                            NetQuantity: {type: "number"},
                            Electronic: {type: "boolean"},
                            Depth: {type: "number"}
                        }
                    }
                },
                data: $scope.appInfo.paperwork.tanks,
                batch: true
            });

            $scope.tanksGridOptions = {
                dataSource: $scope.tanksGridDS,
                columns: [
                    {
                        title: localStrings.getLocalizedString("Tanks"),
                        columns: [
                            {
                                field: "TankNo",
                                title: localStrings.getLocalizedString("No"),
                                width: 40,
                                attributes: {"data-field": "TankNo", "class": "tableCellDisabled"},
                                template: "#=TankNo#"
                            },
                            {
                                field: "ProductId",
                                title: localStrings.getLocalizedString("Product"),
                                width: 65,
                                attributes: {"data-field": "ProductId", "class": "tableCellDisabled description"},
                                template: "#=ProductId#"
                            },
                            {
                                field: "ProductDescription",
                                title: localStrings.getLocalizedString("Description"),
                                attributes: {
                                    "data-field": "ProductDescription",
                                    "class": "tableCellDisabled description"
                                },
                                template: "#=ProductDescription#"
                            }
                        ]
                    },
                    {
                        field: "NoReading",
                        width: 40,
                        template: "<input class=\"noReadChkBox\" type=\"checkbox\"  # if(NoReading){ # checked #}#/>",
                        headerTemplate: "<span style='white-space: initial'>" + localStrings.getLocalizedString("NoRdg") + "</span>",
                        attributes: {"data-field": "NoReading", "class": "k-editable-cell grid-checkbox"}
                    },
                    {
                        title: localStrings.getLocalizedString("Fuel"),
                        columns: [
                            {
                                field: "LargeUnits",
                                width: 50,
                                editor: gridColEditorInput,
                                template: gridColNumber("LargeUnits", "n2"),
                                headerTemplate: "<span ng-if='!appInfo.paperwork.metric'>ft.</span><span ng-if='appInfo.paperwork.metric'>m.</span>",
                                attributes: {"data-field": "LargeUnits", "class": "k-editable-cell"}
                            },
                            {
                                field: "SmallUnits",
                                width: 50,
                                editor: gridColEditorInput,
                                template: gridColNumber("SmallUnits", "n2"),
                                headerTemplate: "<span ng-if='!appInfo.paperwork.metric'>in.</span><span ng-if='appInfo.paperwork.metric'>cm.</span>",
                                attributes: {"data-field": "SmallUnits", "class": "k-editable-cell"}
                            },
                            {
                                field: "PartialUnits",
                                title: localStrings.getLocalizedString("Partial"),
                                width: 70,
                                editor: partialUnitsEditor,
                                template: function (dataItem)
                                {
                                    return getPartialDescFromValue(dataItem);
                                },
                                attributes: {"data-field": "PartialUnits", "class": "k-editable-cell"}
                            },
                            {
                                field: "Quantity",
                                title: localStrings.getLocalizedString("Qty"),
                                width: 120,
                                editor: gridColEditorInput,
                                template: gridColNumber("Quantity", "n2"),
                                attributes: {"data-field": "Quantity", "class": "k-editable-cell"}
                            }
                        ]
                    },
                    {
                        title: localStrings.getLocalizedString("Water"),
                        columns: [
                            {
                                field: "WaterSmallUnits",
                                width: 50,
                                editor: gridColEditorInput,
                                template: gridColNumber("WaterSmallUnits", "n"),
                                headerTemplate: "<span ng-if='!appInfo.paperwork.metric'>in.</span><span ng-if='appInfo.paperwork.metric'>cm.</span>",
                                attributes: {"data-field": "WaterSmallUnits", "class": "k-editable-cell"}
                            },
                            {
                                field: "WaterPartialUnits", title: localStrings.getLocalizedString("Partial"), width: 70, editor: partialUnitsEditor,
                                template: function (dataItem)
                                {
                                    return getPartialDescFromValue(dataItem);
                                },
                                attributes: {"data-field": "WaterPartialUnits", "class": "k-editable-cell"}
                            },
                            {
                                field: "WaterQuantity",
                                title: localStrings.getLocalizedString("Qty"),
                                width: 120,
                                editor: gridColEditorInput,
                                template: gridColNumber("WaterQuantity", "n2"),
                                attributes: {"data-field": "WaterQuantity", "class": "k-editable-cell"}
                            }
                        ]
                    },
                    {
                        title: localStrings.getLocalizedString("TempCorrection"),
                        columns: [
                            {
                                field: "Temperature",
                                width: 50,
                                editor: gridColEditorInput,
                                template: gridColNumber("Temperature", "n"),
                                headerTemplate: "<span ng-if='!appInfo.paperwork.metric'>&deg;F</span><span ng-if='appInfo.paperwork.metric'>&deg;C</span>",
                                attributes: {"data-field": "Temperature", "class": "k-editable-cell"}
                            },
                            {
                                field: "NetQuantity",
                                title: localStrings.getLocalizedString("Net"),
                                width: 50,
                                editor: gridColEditorInput,
                                template: gridColNumber("NetQuantity", "n2"),
                                attributes: {"data-field": "NetQuantity", "class": "k-editable-cell"}
                            }
                        ]
                    },
                    {
                        field: "Electronic",
                        width: 40,
                        template: "<input class=\"elecChkBox\" type=\"checkbox\"  # if(Electronic){ # checked #}#/>",
                        headerTemplate: "<span style='white-space: initial'>" + localStrings.getLocalizedString("ElectRdg") + "</span>",
                        attributes: {"data-field": "Electronic", "class": "k-editable-cell grid-checkbox"}
                    }

                ],
                pageable: false,
                sortable: false,
                editable: true,
                scrollable: false,
                navigatable: true,
                dataBound: function (e)
                {
                    $timeout(function ()
                    {
                        $scope.setupTankRows();
                    },100);

                },
                edit: function (e)
                {
                    var $input = e.container.find("input");
                    var fieldName = $input.attr("name");

                    $input.select();
                    $input.addClass('k-cell-state-selected');

                    var grid = $("#tanksGrid").data("kendoGrid");
                    var currentCell = $(grid.tbody.children().find(".k-edit-cell"));

                    grid.current(currentCell);
                }

            };
        };

        $scope.$on("kendoWidgetCreated", function (event, widget)
        {

            var grid = $("#tanksGrid").data("kendoGrid");

            // the event is emitted for every widget; if we have multiple
            // widgets in this controller, we need to check that the event
            // is for the one we're interested in.

            if (widget === $scope.kendo.tanksGrid)
            {
                grid.dataSource.data($scope.appInfo.paperwork.tanks);

                $("#tanksGrid").find("table").on("keydown", onGridKeydown);

                $scope.setupTankGridColumns(grid);

            }
        });

        $scope.setupTankRows = function ()
        {

            $("#tanksGrid tr").each(function (index, row)
            {

                if (index > 1)
                { // skip header rows
                    var dataItem = $("#tanksGrid").data("kendoGrid").dataItem(this);

                    var $row = $(row);
                    var $rowColumns = $row.find('td');

                    $rowColumns.each(function (index, col)
                    {
                        var $col = $(col);
                        var fieldName = $col.attr('data-field');
                        var $inputElm;

                        $scope.setupGridColumnValidationNotificationUI($col);

                        //setup checkbox columns
                        if (fieldName === "Electronic")
                        {
                            //check to see if grid is editable
                            if ($("#tanksGrid").attr("k-editable") === "false")
                            {
                                $col.removeClass("k-editable-cell");

                                $inputElm = $col.find("input");
                                $inputElm.attr("disabled", true);

                            } else
                            {
                                $col.on("change", "input.elecChkBox", function (e)
                                {
                                    var grid = $("#tanksGrid").data("kendoGrid"),
                                        dataItem = grid.dataItem($(e.target).closest("tr"));

                                    dataItem.set("Electronic", this.checked);
                                });
                            }
                        }

                        if (fieldName === "NoReading")
                        {
                            //check to see if grid is editable
                            if ($("#tanksGrid").attr("k-editable") === "false")
                            {
                                $col.removeClass("k-editable-cell");

                                $inputElm = $col.find("input");
                                $inputElm.attr("disabled", true);

                            } else
                            {
                                $col.on("change", "input.noReadChkBox", function (e)
                                {
                                    var grid = $("#tanksGrid").data("kendoGrid"),
                                        dataItem = grid.dataItem($(e.target).closest("tr"));

                                    dataItem.set("NoReading", this.checked);
                                });
                            }
                        }
                    });

                }
            });
        };

        function onGridKeydown(e)
        {
            if (e.keyCode === kendo.keys.TAB)
            {
                var grid = $("#tanksGrid").data("kendoGrid");

                var current = grid.current();

                if (!current.hasClass("k-editable-cell"))
                {
                    var nextCell = current.nextAll(".k-editable-cell");
                    if (!nextCell[0])
                    {
                        //search the next row
                        var nextRow = current.parent().next();
                        nextCell = current.parent().next().children(".k-editable-cell:first");
                    }
                    grid.current(nextCell);
                    grid.editCell(nextCell[0]);
                }
            }
        }

        $scope.setupTankGridColumns = function (grid) {

            if (!($scope.appInfo.paperwork.useLargeUnits && !$scope.appInfo.paperwork.quantityOnly)) {
                grid.hideColumn("LargeUnits");
            }

            if ($scope.appInfo.paperwork.quantityOnly) {
                grid.hideColumn("SmallUnits");
                grid.hideColumn("PartialUnits");
            }

            if (!($scope.appInfo.paperwork.trackWaterLevels && !$scope.appInfo.paperwork.quantityOnly)) {
                grid.hideColumn("WaterSmallUnits");
                grid.hideColumn("WaterPartialUnits");
            }

            if (!$scope.appInfo.paperwork.trackWaterLevels) {
                grid.hideColumn("WaterQuantity");
            }

            if (!$scope.appInfo.paperwork.useTempCorrections) {
                grid.hideColumn("Temperature");
                grid.hideColumn("NetQuantity");
            }

        };

        $scope.gridInputCellOnBlur = function (dataItem, fieldName)
        {

            if (fieldName === "LargeUnits")
            {
                dataItem.LargeUnits = (!dataItem.LargeUnits || dataItem.LargeUnits === "") ? 0 : dataItem.LargeUnits;
                $scope.calculateTankValues(dataItem);
            }

            if (fieldName === "SmallUnits")
            {
                dataItem.SmallUnits = (!dataItem.SmallUnits || dataItem.SmallUnits === "") ? 0 : dataItem.SmallUnits;
                $scope.calculateTankValues(dataItem);
            }

            if (fieldName === "Quantity")
            {
                dataItem.Quantity = (!dataItem.Quantity || dataItem.Quantity === "") ? 0 : dataItem.Quantity;
                $scope.calculateVolumeForTemperature(dataItem);
            }

            if (fieldName === "WaterSmallUnits")    
            {
                dataItem.WaterSmallUnits = (!dataItem.WaterSmallUnits || dataItem.WaterSmallUnits === "") ? 0 : dataItem.WaterSmallUnits;
                $scope.calculateWaterVolumeValues(dataItem);
            }

            if (fieldName === "WaterQuantity")
            {
                dataItem.WaterQuantity = (!dataItem.WaterQuantity || dataItem.WaterQuantity === "") ? 0 : dataItem.WaterQuantity;
                $scope.calculateVolumeForTemperature(dataItem);
            }

            if (fieldName === "Temperature")
            {
                dataItem.Temperature = (!dataItem.Temperature || dataItem.Temperature === "") ? 0 : dataItem.Temperature;
                $scope.calculateVolumeForTemperature(dataItem);
            }


        };

        $scope.calculateTankValues = function (dataItem)
        {
            var modelRow = $("#tanksGrid").data("kendoGrid").dataSource.getByUid(dataItem.uid);

            modelRow.set('NoReading', false);

            var depth = PaperworkTanksService.calculateTankDepth(dataItem,
                $scope.appInfo.paperwork.metric);

            modelRow.set('Depth', depth);

            var quantity = PaperworkTanksService.getVolumeFromTankChart(dataItem,
                $scope.appInfo.paperwork.tankCharts);

            modelRow.set('Quantity', quantity);

            $scope.calculateVolumeForTemperature(dataItem);
        };

        $scope.calculateVolumeForTemperature = function (dataItem)
        {
            var modelRow = $("#tanksGrid").data("kendoGrid").dataSource.getByUid(dataItem.uid);

            var netQuantity = PaperworkTanksService.adjustVolumeForTemperature($scope.appInfo.paperwork.useTempCorrections,
                dataItem, $scope.appInfo.paperwork.tempCharts);

            modelRow.set('NoReading', false);
            modelRow.set('NetQuantity', netQuantity);

        };

        $scope.calculateWaterVolumeValues = function (dataItem)
        {
            var modelRow = $("#tanksGrid").data("kendoGrid").dataSource.getByUid(dataItem.uid);

            modelRow.set('NoReading', false);

            var depth = PaperworkTanksService.calculateWaterDepth(dataItem);

            modelRow.set('WaterDepth', depth);

            var quantity = PaperworkTanksService.getWaterVolumeFromTankChart(dataItem,
                $scope.appInfo.paperwork.tankCharts);

            modelRow.set('WaterQuantity', quantity);

            $scope.calculateVolumeForTemperature(dataItem);

        };

        $scope.validateTankOverShort = function (referenceTank)
        {
            var hasWarning = false;

            //First check to see if we even care.
            if (!$scope.appInfo.paperwork.UseOSWarnings)
            {
                return {
                    HasWarning: false,
                    Min: 0,
                    Max: 0
                };
            }

            //Figure out what the base tank is going to be.
            if (referenceTank.InventoryTankNo !== referenceTank.TankNo)
            {
                for (var x = 0; x < $scope.appInfo.paperwork.tanks.length; x++)
                {
                    if ($scope.appInfo.paperwork.tanks[x].TankNo === referenceTank.InventoryTankNo)
                    {
                        referenceTank = $scope.appInfo.paperwork.tanks[x];
                        break;
                    }
                }
            }
            //Then find the tolerance
            var tolerance = (referenceTank.EndQty * ($scope.appInfo.paperwork.OSWarningPct / 100)) + $scope.appInfo.paperwork.OSWarningUnits;
            var min = referenceTank.EndQty - tolerance;
            var max = referenceTank.EndQty + tolerance;
            //Find the total value of all tanks manifolded togather then check the tolerances.
            var totalValue = $scope.calculateTankEndQuantity(referenceTank);
            hasWarning = (totalValue < min || totalValue > max) && !referenceTank.NoReading;

            if (hasWarning)
            {
                $scope.tankCommentIsRequired = true;
            }

            return {
                HasWarning: hasWarning,
                Min: min,
                Max: max
            } ;
        };

        $scope.calculateTankEndQuantity = function (tank)
        {
            if (tank.TankNo === tank.InventoryTankNo)
                return tank.Quantity;
            var inventoryTankNo = tank.InventoryTankNo;
            var quantity = 0.00;
            for (var x = 0; x < $scope.appInfo.paperwork.tanks.length; x++)
            {
                if ($scope.appInfo.paperwork.tanks[x].InventoryTankNo === inventoryTankNo)
                    quantity += $scope.appInfo.paperwork.tanks[x].Quantity;
            }
            return quantity;
        };

        $scope.setupGridColumnValidationNotificationUI = function ($col)
        {

            var fieldName = $col.attr('data-field');

            $col.qtip({
                id: "qtip-" + fieldName,
                content: {
                    text: ""
                },
                position: {
                    adjust: {
                        x: -10, y: -10
                    }
                },
                style: {
                    classes: "toolTipStyle"
                }
            });

            $col.qtip('disable', true);

        };

        $scope.processGridColumnValidationNotification = function ($row, fieldName, errorMessages, warningMessages)
        {

            var idx = 0;

            var hasErrors = (errorMessages.length > 0);
            var hasWarnings = (warningMessages.length > 0);

            var $col = $row.find("[data-field='" + fieldName + "']");

            //clear classes
            $col.removeClass("td-error");
            $col.removeClass("td-warning");

            if (hasErrors || hasWarnings)
            {

                var messageList = $("<div></div>");

                if (hasErrors)
                {
                    var errorList = $("<div style='padding-bottom: 2px'>" + localStrings.getLocalizedString("Error") + " :</div>");
                    for (idx = 0; idx < errorMessages.length; idx++)
                    {
                        errorList.append("<li style='padding: 1px 0px 1px 7px'>" + errorMessages[idx] + "</li>");
                    }

                    messageList.append(errorList);
                }

                if (hasWarnings)
                {
                    var warningList = $("<div style='padding - bottom: 2px'>" + localStrings.getLocalizedString("Warning") + " :</div>");
                    for (idx = 0; idx < warningMessages.length; idx++)
                    {
                        warningList.append("<li style='padding: 1px 0px 1px 7px'>" + warningMessages[idx] + "</li>");
                    }

                    messageList.append(warningList);
                }

                $col.qtip('option', 'content.text', messageList);

                if (hasErrors)
                {
                    $col.addClass("td-error");
                    $col.qtip('option', 'style.classes', 'toolTipStyle-error');
                } else
                {
                    $col.addClass("td-warning");
                    $col.qtip('option', 'style.classes', 'toolTipStyle-warning');
                }

                $col.qtip('disable', false);
            }
            else
            {
                $col.qtip('disable', true);
            }
        };

        var tankRowValidation =
        {
            validator: function (tank, parms)
            {
                var isValid = true;

                var $row = $("#tanksGrid").data("kendoGrid").tbody.find("tr[data-uid='" + tank.uid + "']");

                var errorFuelQty = [], warningFuelQty = [];

                // fuel quantity validation
                var maxTankVolume = PaperworkTanksService.getMaxVolumeFromTankChart(tank, $scope.appInfo.paperwork.tankCharts);
                if (tank.Quantity > maxTankVolume)
                {
                    errorFuelQty.push(localStrings.getLocalizedString("TankReadingExceeds"));
                    isValid = false;
                }

                var result = $scope.validateTankOverShort(tank);
                if (result.HasWarning)
                {
                    warningFuelQty.push(localStrings.getLocalizedString("FuelMeasurementsEntered") + " " + result.Min + " " + localStrings.getLocalizedString("And") + " " + result.Max);
                }

                $scope.processGridColumnValidationNotification($row, 'Quantity', errorFuelQty, warningFuelQty);

                return isValid;
            }
        };

        $scope.validateTanks = function ()
        {

            var isValid = true;

            var tanksGrid = $("#tanksGrid").data("kendoGrid");

            $scope.tankCommentIsRequired = false;
            angular.forEach(tanksGrid.dataSource.data(), function (tank)
            {
                isValid = isValid && tankRowValidation.validator(tank);
            });

            return isValid;
        };

        $scope.loadTanks = function (refreshDataSource)
        {
            if (!$scope.locationInfo.sideMenuData.paperwork.tanks)
            {
                if ($scope.appInfo.paperwork.viewOnly)
                    $scope.goTo("/Paperwork/ViewPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
                else
                    $scope.goTo("/Paperwork/EnterPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
            }

            angular.forEach($scope.appInfo.paperwork.context.paperworkSites, function (site, index)
            {
                if (site.SiteKey === $scope.appInfo.paperwork.batch.SiteKey)
                    $scope.appInfo.paperwork.context.selectedSiteObj = site;
            });

            PaperworkTanksService.loadTanks(
                $scope.appInfo.paperwork.batch.Key,
                $scope.appInfo.paperwork.batch.SiteKey,
                $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true)
            ).then(function (data)
                {
//                    if(data.count == 0){
                    //TODO: No tanks, do something...

//                  }
                    $scope.appInfo.paperwork.tanks = data.PaperworkTanks;
                    $scope.appInfo.paperwork.postedTanks = data.PostedPaperworkTanks;
                    $scope.appInfo.paperwork.partialUnits = data.PartialUnitsList;
                    $scope.appInfo.paperwork.metric = data.Metric;
                    $scope.appInfo.paperwork.quantityOnly = data.QuantityOnly;
                    $scope.appInfo.paperwork.useLargeUnits = data.UseLargeUnits;
                    $scope.appInfo.paperwork.useTempCorrections = data.UseTempCorrections;
                    $scope.appInfo.paperwork.trackWaterLevels = data.TrackWaterLevels;
                    $scope.appInfo.paperwork.tankCharts = data.TankCharts;
                    $scope.appInfo.paperwork.tempCharts = data.TempCharts;
                    $scope.appInfo.paperwork.OSComments = data.OSComments;
                    $scope.appInfo.paperwork.UseOSWarnings = data.UseOSWarnings;
                    $scope.appInfo.paperwork.OSWarningUnits = data.OSWarningUnits;
                    $scope.appInfo.paperwork.OSWarningPct = data.OSWarningPct;
                    $scope.appInfo.paperwork.RequireOSComments = data.RequireOSComments;

                    if (!refreshDataSource)
                    {
                        $scope.initializeTanksGrid();
                    } else
                    {
                        var grid = $("#tanksGrid").data("kendoGrid");
                        if(angular.isUndefinedOrNullOrEmpty(grid)) return;
                        grid.dataSource.data(data.PaperworkTanks);

                        $scope.validateTanks(); // call00 validate to re add any warnings after save
                    }

                    $scope.tanksLoaded = true;

                    if ($scope.appInfo.paperwork.postedTanks.length > 0) {
                        $scope.postedReadingsExist = true;
                    }

                    if ($scope.appInfo.paperwork.tanks.length > 0) {
                        $scope.tanksEditable = !($scope.appInfo.paperwork.context.lockPaperworkAfterDru && $scope.appInfo.paperwork.tanks[0].DRUPosted);
                    }
                });
        };

        $scope.tankCommentIsRequired = false;
        $scope.tankCommentRequiredError = false;

        var tankCommentValidation =
        {
            name: 'validateTankComment',
            validationMessage: localStrings.getLocalizedString("FuelMeasurementsNotInToleranceComment"),
            validatorError: function ()
            {
                $scope.tankCommentRequiredError = false;

                if (!$scope.appInfo.paperwork.UseOSWarnings || !$scope.appInfo.paperwork.RequireOSComments || !$scope.tankCommentIsRequired)
                {
                    return true; // not required or not overshort warning
                }


                if ($scope.appInfo.paperwork.NewOSComments.length > 0 || ($scope.appInfo.paperwork.OSComments.Text && $scope.appInfo.paperwork.OSComments.Text.length > 0))
                {
                    return true;
                }

                $scope.tankCommentRequiredError = true;
                return false;
            }
        };

        $scope.tankCommentErrorOnBlur = function ()
        {
            $("#tankCommmentValidationMessage").attr('style', 'display: none !important;');
        };

        $scope.tankCommentErrorOnFocus = function ()
        {
            $("#tankCommmentValidationMessage").attr('style', 'display: inline-block !important;');
        };

        var navToMeters = $rootScope.$on('event:NavigateToMeters', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.previous('/Paperwork/ViewPaperwork/');
            else
                $scope.previous('/Paperwork/EnterPaperwork/');
        });

        $scope.$on('$destroy', navToMeters);

        var navToForms = $rootScope.$on('event:NavigateToForms', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.next('/Paperwork/ViewPaperworkDealerForms/');
            else
                $scope.next('/Paperwork/EnterPaperworkDealerForms/');
        });

        $scope.$on('$destroy', navToForms);

        var navToDeliveries = $rootScope.$on('event:NavigateToDeliveries', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.next('/Paperwork/ViewPaperworkDeliveries/');
            else
                $scope.next('/Paperwork/EnterPaperworkDeliveries/');
        });

        $scope.$on('$destroy', navToDeliveries);


        $scope.next = function (path)
        {
            if (!validatorSvc.executeValidations()) {
                return;
            }

            if (!$scope.appInfo.paperwork.viewOnly && $scope.tanksEditable)
            {
                $scope.savePaperworkTanks(path + $scope.appInfo.paperwork.paperworkKey, false, false);
            } else {
                $scope.goTo(path + $scope.appInfo.paperwork.paperworkKey);
            }
        };

        $scope.previous = function (path)
        {
            if (!validatorSvc.executeValidations()) {
                return;
            }

            if (!$scope.appInfo.paperwork.viewOnly && $scope.tanksEditable)
            {
                $scope.savePaperworkTanks(path + $scope.appInfo.paperwork.paperworkKey, false, false);
            } else {
                $scope.goTo(path + $scope.appInfo.paperwork.paperworkKey);
            }
        };

        $scope.close = function (path)
        {
            if (!$scope.appInfo.paperwork.viewOnly && $scope.tanksEditable)
            {
                $scope.savePaperworkTanks("", false, false);
            } else {
                $scope.goTo("/Paperwork/EnterPaperwork/");
            }
        };

        $scope.submit = function ()
        {
            $scope.savePaperworkTanks("", true, false);
        };

        function initPopup() {
            var popupTitle = localStrings.getLocalizedString("PostedFuelTankReadings");
            var postedHtml = '<div class="popupTanksTable petronetKendoGrid"></div>';

            $scope.modalifier.show(popupTitle, postedHtml);

            setTimeout(function () {
                //get and modify the original grids options
                $scope.gridOptions = {};
                angular.copy($scope.tanksGridOptions, $scope.gridOptions);
                $scope.gridOptions.editable = false;

                // manually update imperial/metric units in header (large units, small units, temperature) - angular directives are not evaluated in popup
                $scope.gridOptions.columns[2].columns[0].headerTemplate = $scope.appInfo.paperwork.metric ? "<span>m.</span>" : "<span>ft.</span>";
                $scope.gridOptions.columns[2].columns[1].headerTemplate = $scope.appInfo.paperwork.metric ? "<span>cm.</span>" : "<span>in.</span>";
                $scope.gridOptions.columns[3].columns[0].headerTemplate = $scope.appInfo.paperwork.metric ? "<span>cm.</span>" : "<span>in.</span>";
                $scope.gridOptions.columns[4].columns[0].headerTemplate = $scope.appInfo.paperwork.metric ? "<span>&deg;C</span>" : "<span>&deg;F</span>";

                // grab the empty element and make it a kendo grid
                var el = $('div.popupTanksTable');
                el.kendoGrid($scope.gridOptions);

                // checkbox imputs must be manually disabled
                el.find('.grid-checkbox').attr('readonly', true);
                //$('input.noReadChkBox').Attr('disabled', true);

                // hide columns based on record type
                var grid = el.data('kendoGrid');
                $scope.setupTankGridColumns(grid);

                // set datasource
                grid.dataSource.data($scope.appInfo.paperwork.postedTanks);
                grid.refresh();
            }, 0);
        }

        $scope.showPostedReadings = function () {
            if ($('div.popupTanksTable').length > 0)
                $scope.modalifier.display = true;
            else
                initPopup();
        };

        $scope.submitPaperwork = function (submit)
        {
            var fuelMetersAppInfo = $scope.appInfo.paperwork.apps[0];

            //If we have fuelMeters and fuelMeters are required, but not saved; then throw error
            if (fuelMetersAppInfo.HasData === 0 && fuelMetersAppInfo.IsRequired !== 0) {
                $timeout(function () {
                    $scope.modalifier.showSavedError();
                }, 100);
                return;
            }

            var submitPaperworkCmdParms =
            {
                SiteKey: $scope.appInfo.paperwork.batch.SiteKey,
                PaperworkDate: $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true),
                EmailAddress: $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                SubmitPaperwork: submit,
                PreviouslySubmitted: $scope.appInfo.paperwork.batch.HasBeenSubmitted
            };
            PaperworkService.SubmitPaperwork(submitPaperworkCmdParms).then(function (data)
            {
                if (submit)
                    $scope.goTo("Paperwork/InventorySummary/" + $scope.appInfo.paperwork.batch.SiteKey + "/" + $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true));
            });
        };

        $scope.savePaperworkTanks = function (path, submit, close)
        {
            if ($('#tanksGrid .k-dirty-cell').length > 0) {

                if (!$scope.validateTanks()) {
                    return;
                } else {
                    if ($scope.tankCommentIsRequired) {
                        var commentIsValid = tankCommentValidation.validatorError();

                        if (!commentIsValid) {
                            return;
                        }
                    }
                }

                $scope.resetDirtyFlags($scope.tanksForm);

                angular.copy($scope.tanksGridDS.data().toJSON(), $scope.appInfo.paperwork.tanks);

                var tankHeader = {
                    Key: $scope.appInfo.paperwork.tanks[0].HeaderKey,
                    SiteKey: $scope.appInfo.paperwork.batch.SiteKey,
                    PaperworkDate: $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true),
                    Status: $scope.appInfo.paperwork.tanks[0].Status,
                    StickType: $scope.appInfo.paperwork.tanks[0].StickType,
                    Ts: $scope.appInfo.paperwork.tanks[0].HeaderTimeStamp,
                    PaperworkBatchKey: $scope.appInfo.paperwork.batch.Key,
                    AppKey: $scope.locationInfo.sideMenuData.paperwork.tanks.Key,
                    UserName: $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                    OSComments: {
                        Key: $scope.appInfo.paperwork.OSComments.Key,
                        FuelStkHdrKey: $scope.appInfo.paperwork.OSComments.FuelStkHdrKey,
                        Text: $scope.appInfo.paperwork.OSComments.Text,
                        NewText: $scope.appInfo.paperwork.NewOSComments,
                        Ts: $scope.appInfo.paperwork.OSComments.Ts,
                        Timestamp: $scope.appInfo.paperwork.OSComments.Timestamp
                    }
                };

                PaperworkTanksService.savePaperworkTanks(tankHeader, $scope.appInfo.paperwork.tanks).then(function (data) {
                    //get updated data
                    $scope.loadTanks(true);

                    $scope.appInfo.paperwork.NewOSComments = "";
                });
            }

            if (submit) {
                $scope.submitPaperwork(true);
                return;
            }
            if (close) {
                $scope.submitPaperwork(false);
                return;
            }
            if (path.length > 0)
                $scope.goTo(path);
        };

        $scope.getPartialValue = function (value)
        {
            for (var x in $scope.appInfo.paperwork.partialUnits)
            {
                if ($scope.appInfo.paperwork.partialUnits[x] === value)
                    return $scope.appInfo.paperwork.partialUnits[x];
            }
        };

    })
    .controller('enterPaperworkDeliveriesCtrl', function ($scope, $rootScope, $routeParams, PaperworkService, $filter, PaperworkDeliveriesService,
                                                          $location, WebFunctions, validatorSvc, $timeout, $q, $compile, templates, localStrings, globalizationSvc)
    {
        'use strict';
        $scope.kendo = {};
        $scope.appInfo.paperwork.paperworkKey = 0;
        $scope.appInfo.paperwork.deliveries = {};
        $scope.appInfo.paperwork.deliveriesList = {};
        $scope.appInfo.paperwork.deliveries.fuelDelivery = {};
        $scope.appInfo.paperwork.deliveries.context = {};
        $scope.appInfo.paperwork.deliveries.context.AuthorizedVendors = [];
        $scope.appInfo.paperwork.deliveries.context.AuthorizedTerminals = [];
        $scope.appInfo.paperwork.deliveries.context.AuthorizedCarriers = [];
        $scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryFreight = [];
        $scope.appInfo.paperwork.deliveries.fuelDelivery.VendKey = -1;
        $scope.appInfo.paperwork.deliveries.fuelDelivery.TrmnlKey = -1;
        $scope.appInfo.paperwork.deliveries.fuelDelivery.CarrierKey = -1;
        $scope.deliveriesLoaded = false;
        $scope.deliveryFilteredTanks = [];

        $scope.currentView = 0;  // 0 = record selector; 1= entry

        $scope.changeView = function (view)
        {

            // 0 = record selector; 1= entry

            if (view === 1)
            {  // chnange to entry
                $scope.currentView = 1;
            } else
            {
                $scope.currentView = 0;
                $scope.appInfo.paperwork.deliveries.fuelDelivery = {}; // clear delivery
            }
        };

        $scope.freightModalFormDelegate = {};

        //grid validations
        $scope.productValidation = {hasErrors: false, errorDescription: ""};

        // controller destructor
        $scope.$on("$destroy", function handler()
        {
            $rootScope.scopesToValidate = [];
        });

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }         

            $scope.appInfo = $scope.appInfo || {};

            $scope.appInfo.paperwork.viewOnly = $location.$$path.indexOf('/ViewPaperworkDeliveries/') > -1;
            if ($routeParams.paperworkKey)
            {
                $scope.appInfo.paperwork.paperworkKey = $routeParams.paperworkKey;
            }
            $scope.appInfo.paperwork.NewOSComments = '';
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenu = templates.getTemplate("PaperworkSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = '1';
            //            $scope.getWebProfileMessage(19);
            $scope.globalizationSvc = globalizationSvc;

            $scope.newDeliveryProduct = {};

            $scope.appInfo.paperwork.batch = {};

            if (!$scope.appInfo.paperwork.context)
            {
                PaperworkService.getPaperworkContext($scope.appInfo.webUser.CurrentAuthorization.CustContKey, true).then(
                    function (data)
                    {
                        $scope.appInfo.paperwork.context = {};
                        $scope.appInfo.paperwork.context.paperworkSites = data.PaperworkSites;
                        $scope.appInfo.paperwork.context.selectedSite = data.SelectedSiteKey;
                        $scope.appInfo.paperwork.context.lockPaperworkAfterDru = data.LockPaperworkAfterDru;

                        $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance = data.CalculatedPriceToPriorDaysPriceVariance;
                        $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance = data.CalculatedPriceToCapturedRetailPriceVariance;
                        $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance = data.NetSalesDollarsToCapturedRetailPriceNetUnitsVariance;

                        $scope.locationInfo.showEnterPaperworkMenuItem = $scope.appInfo.paperwork.context.paperworkSites[0] ?
                            $scope.appInfo.paperwork.context.paperworkSites[0].CanEnterPaperwork :
                            false;

                        if (!$scope.locationInfo.showEnterPaperworkMenuItem)
                        {
                            $scope.locationInfo.sideMenuData.subMenu = '2';
                            $location.path("/Paperwork/ReviewPaperwork");
                        }
                    }
                );
            }

            if ($scope.appInfo.paperwork.paperworkKey > 0)
            {
                //We are loading a known existing paperwork batch
                PaperworkService.OpenPaperworkBatchByKey(
                    $scope.appInfo.paperwork.paperworkKey,
                    $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                    $scope.appInfo.webUser.Id,
                    $scope.appInfo.paperwork.viewOnly
                ).then(function (data)
                    {
                        $scope.appInfo.paperwork.batch = data.PaperworkBatch.PaperworkBatch;
                        $scope.appInfo.paperwork.apps = data.PaperworkBatch.PaperworkApps;
                        $scope.appInfo.paperwork.form = data.PaperworkBatch.PaperworkForm;
                        $scope.appInfo.paperwork.siteInfo = data.PaperworkBatch.PaperworkSiteInfo;

                        $rootScope.$broadcast('event:PaperworkLoaded', $scope.appInfo.paperwork.viewOnly, $scope.appInfo.paperwork.paperworkKey);

                        var nextApp = true;
                        angular.forEach($scope.appInfo.paperwork.apps, function (app, index)
                        {
                            if (app.MenuCode === 'DREFD')
                            {
                                $scope.getDeliveriesWithContext();
                                nextApp = false;
                            }
                        });
                        if (nextApp)
                        {
                            if ($scope.appInfo.paperwork.viewOnly)
                                $scope.goTo("/Paperwork/ViewPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
                            else
                                $scope.goTo("/Paperwork/EnterPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
                        }
                    }, function ()
                    {
                        $location.path("/Paperwork/ReviewPaperwork");
                    });
            } else
            {
                //todo: goback...
            }
            $scope.locationInfo.sideMenuData.pwSubMenu = '4';

            $scope.hideModals();
        };

        var newPWBatchLoadedListener = $rootScope.$on('event:NewPaperworkBatchLoaded', function (event, data)
        {
            if ($scope.locationInfo.sideMenuData.paperwork.tanks)
                $scope.getDeliveriesWithContext();
            else
            {
                if ($scope.appInfo.paperwork.viewOnly)
                    $scope.goTo("/Paperwork/ViewPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
                else
                    $scope.goTo("/Paperwork/EnterPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
            }
        });

        // $scope $destroy
        $scope.$on('$destroy', newPWBatchLoadedListener);

        $scope.getDeliveriesWithContext = function ()
        {
            if (!$scope.locationInfo.sideMenuData.paperwork.deliveries)
            {
                if ($scope.appInfo.paperwork.viewOnly)
                    $scope.goTo("/Paperwork/ViewPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
                else
                    $scope.goTo("/Paperwork/EnterPaperworkDealerForms/" + $scope.appInfo.paperwork.paperworkKey);
            }

            angular.forEach($scope.appInfo.paperwork.context.paperworkSites, function (site, index)
            {
                if (site.SiteKey === $scope.appInfo.paperwork.batch.SiteKey)
                {
                    $scope.appInfo.paperwork.context.selectedSiteObj = site;
                }
            });

            PaperworkDeliveriesService.getDeliveriesWithContext(
                $scope.appInfo.paperwork.batch.Key, $scope.appInfo.paperwork.viewOnly
            ).then(function (data)
                {
                    $scope.appInfo.paperwork.deliveries = {};
                    $scope.appInfo.paperwork.deliveries.fuelDelivery = {};
                    $scope.appInfo.paperwork.deliveries.context = data.Context;
                    $scope.appInfo.paperwork.deliveries.deliveriesList = data.FuelDeliveriesList;

                    // if there are no deliveries for this paperwork batch then drop in to add mode for new delivery
                    if (data.FuelDeliveriesList.length === 0)
                    {
                        // currently petronet can only edit petronet source fuel delivery so expect on one row returned
                        data.NewDelivery.LiftDatetime = globalizationSvc.formatDateTime(data.NewDelivery.LiftDatetime, 'g');
                        data.NewDelivery.DelivDateTime = globalizationSvc.formatDateTime($scope.appInfo.paperwork.batch.PaperworkDate, 'g');

                        $scope.appInfo.paperwork.deliveries.fuelDelivery = data.NewDelivery;

                        $scope.fuelDeliveriesCount = data.FuelDeliveriesList.length;

                        $scope.initializeDeliveriesGrid();

                        $scope.changeView(1);
                    }

                    $scope.deliveriesLoaded = true;
                });
        };

        var gridColEditorInput = function (container, options)
        {

            var fieldName = "'" + options.field + "'";

            var required = $(container).attr('required'); // field is required

            // For some browsers, `attr` is undefined; for others,
            // `attr` is false.  Check for both.
            if (typeof required !== typeof undefined && required !== false)
            {
                $('<input type="text" name="' + options.field + '" data-bind="value:' + options.field + '"  required data-Required-msg="' + localStrings.getLocalizedString("Required") + '" />')
                    .appendTo(container);
            } else
            {
                $('<input type="text" name="' + options.field + '" data-bind="value:' + options.field + '" />')
                    .appendTo(container);
            }

        };

        var gridColNumber = function (field, format)
        {
            return '<label>#= kendo.toString(' + field + ', "' + format + '", "' + globalizationSvc.getLocale() + '")#</label>';
        };

        var productEditor = function (container, options)
        {
            $("<input name='" + options.field + "' data-text-field='Id' data-value-field='Key' data-bind='value:" + options.field + "' required='required' data-Required-msg='" + localStrings.getLocalizedString("Required") + "' />")

                .appendTo(container)
                .kendoComboBox({
                    id: "productList",
                    dataSource: {
                        data: $scope.appInfo.paperwork.deliveries.context.SiteTankProducts
                    },
                    filter: "contains",
                    dataTextField: localStrings.getLocalizedString("ID"),
                    dataValueField: "Key",
                    template: "<table><tr><td width='75px'>${ Id }</td><td>${ Description }</td></tr></table>",
                    change: function (e)
                    {

                        var selectionElement = $(e.sender.element[0]).parent().find(".k-dropdown-wrap");

                        // check if valid
                        if ($(e.sender.element[0]).hasClass("k-invalid"))
                        {
                            selectionElement.addClass("k-selection-invalid");
                        } else
                        {
                            selectionElement.removeClass("k-selection-invalid");
                        }

                        if (this.value() && this.selectedIndex === -1)
                        {
                            this.value('');
                            this._filterSource({
                                value: "",
                                field: this.options.dataTextField,
                                operator: "contains"
                            });
                            return;
                        }

                        var dataItem = e.sender.dataItem();
                        options.model.set("ProdId", dataItem.Id);
                        options.model.set("ProdDesc", dataItem.Description);
                    },
                    open: function(e) {
                        var width = $(".k-combobox").width() + 300;
                        $(".k-animation-container").width(width);
                        $(".k-list-container").width(width - 4);
                    }
                });

            $('<span class="k-invalid-msg" data-for="' + options.field + '"></span>').appendTo(container);

        };

        var tankEditor = function (container, options)
        {
            $("<input name='" + options.field + "' data-text-field='TankNo' data-value-field='TankNo' data-bind='value:" + options.field + "' required='required' data-Required-msg='" + localStrings.getLocalizedString("Required") + "' />")

                .appendTo(container)
                .kendoComboBox({
                    id: "tankList",
                    filter: "contains",
                    dataSource: {
                        data: $scope.getFilteredTanksList(options.model.ProdKey)
                    },
                    dataTextField:  "TankNo",
                    dataValueField: "TankNo",
                    change: function (e)
                    {

                        var selectionElement = $(e.sender.element[0]).parent().find(".k-dropdown-wrap");

                        // check if valid
                        if ($(e.sender.element[0]).hasClass("k-invalid"))
                        {
                            selectionElement.addClass("k-selection-invalid");
                        } else
                        {
                            selectionElement.removeClass("k-selection-invalid");
                        }

                        if (this.value() && this.selectedIndex === -1)
                        {
                            this.value('');
                            this._filterSource({
                                value: "",
                                field: this.options.dataTextField,
                                operator: "contains"
                            });
                            return;
                        }

                        var dataItem = e.sender.dataItem();
                        options.model.set("SelectedTank", dataItem);
                    }
                });

            $('<span class="k-invalid-msg" data-for="' + options.field + '"></span>').appendTo(container);
        };

        $scope.getProductByKey = function (prodKey)
        {
            return $scope.appInfo.paperwork.deliveries.context.SiteTankProducts.find({Key: prodKey});
        };

        $scope.initializeDeliveriesGrid = function ()
        {

            $scope.deliveriesGridDS = new kendo.data.DataSource({
                schema: {
                    model: {
                        fields: {
                            ProdKey: {type: "number"},
                            ProdId: {type: "string"},
                            ProdDesc: {type: "string"},
                            TankNo: {type: "number"},
                            DelivGrossQty: {type: "number"},
                            DelivNetQty: {type: "number"},
                            SelectedTank: {}
                        }
                    }
                },
                data: $scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryProducts,
                batch: true
            });

            $scope.deliveriesGridOptions = {
                dataSource: $scope.deliveriesGridDS,
                columns: [
                    {
                        title: localStrings.getLocalizedString("Product"),
                        columns: [
                            {
                                field: "ProdKey",
                                title: localStrings.getLocalizedString("ID"),
                                width: 100,
                                attributes: {"data-field": "ProdKey", "class": "k-editable-cell", "required": "true"},
                                template: "#=ProdId#",
                                editor: productEditor
                            },
                            {
                                field: "ProdDesc",
                                title: localStrings.getLocalizedString("Description"),
                                attributes: {"data-field": "ProdDesc", "class": "tableCellDisabled description"},
                                template: "#=ProdDesc#",
                                editor: function (element, options)
                                {
                                    element.text(options.model.ProdDesc);
                                }
                            }
                        ]
                    },
                    {
                        field: "FuelDeliveryTanks[0].TankNo",
                        width: 100,
                        template: "#= FuelDeliveryTanks[0].TankNo #",
                        editor: tankEditor,
                        headerTemplate: localStrings.getLocalizedString("Tank"),
                        attributes: {"data-field": "TankNo", "class": "k-editable-cell", "required": "true"}
                    },
                    {
                        title: localStrings.getLocalizedString("Units"),
                        columns: [
                            {
                                field: "DelivGrossQty",
                                title: localStrings.getLocalizedString("Gross"),
                                width: 80,
                                attributes: {
                                    "data-field": "DelivGrossQty",
                                    "class": "k-editable-cell",
                                    "required": "true"
                                },
                                template: gridColNumber("DelivGrossQty", "n2"),
                                editor: gridColEditorInput
                            },
                            {
                                field: "DelivNetQty",
                                title: localStrings.getLocalizedString("Net"),
                                width: 80,
                                attributes: {
                                    "data-field": "DelivNetQty",
                                    "class": "k-editable-cell",
                                    "required": "true"
                                },
                                template: gridColNumber("DelivNetQty", "n2"),
                                editor: gridColEditorInput
                            }
                        ]
                    },
                    {
                        field: "DeleteDeliveryBtn",
                        template: "<i class='fa fa-trash-o paperwork-table-button' ng-click='deleteDeliveryProductRow(dataItem)'></i>",
                        width: 60,
                        attributes: {"data-field": "DeleteDeliveryBtn"},
                        headerTemplate: "&nbsp"
                    }
                ],
                pageable: false,
                sortable: false,
                editable: $scope.deliveryIsEditable(),
                scrollable: false,
                navigatable: true,
                dataBound: function (e)
                {
                    $scope.setupDeliveryRows();
                },
                edit: function (e)
                {
                    var $input = e.container.find("input");
                    var fieldName = $input.attr("name");

                    // alternative (if you don't have the name attribute in your editable):
                    // var columnIndex = this.cellIndex(e.container);
                    // var fieldName = this.thead.find("th").eq(columnIndex).data("field");

                    if (!deliveriesGridColIsEditable(fieldName, e.model))
                    {
                        $input.prop('readonly', true);
                        //this.closeCell(); // prevent editing
                    } else
                    {
                        $input.select();
                        $input.addClass('k-cell-state-selected');
                    }

                    var grid = $("#deliveriesGrid").data("kendoGrid");
                    var currentCell = $(grid.tbody.children().find(".k-edit-cell"));

                    grid.current(currentCell);
                }

            };

        };

        $scope.$on("kendoWidgetCreated", function (event, widget)
        {

            var grid = $("#deliveriesGrid").data("kendoGrid");

            // the event is emitted for every widget; if we have multiple
            // widgets in this controller, we need to check that the event
            // is for the one we're interested in.

            if (widget === $scope.kendo.deliveriesGrid)
            {
                grid.dataSource.data($scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryProducts);

                $("#deliveriesGrid").find("table").on("keydown", onGridKeydown);

                if ($scope.deliveryIsEditable())
                {
                    var addDeliveryAction = $('<div class="petronetGridActionFooterRow" style="display: flex; flex-direction: row"><i style="padding: 12px 0 0 3px;" id="addDeliveryAction" class="fa fa-plus petronetGridActionIcon" ng-click="addDeliveryGridRow()"></i><div id="petronetGridActionFooterRowMiddle" style="width: 100%"></div><div style="margin-left: 5px; " id="petronetGridActionFooterRow"></div></div>').appendTo($('#deliveriesGrid'));
                    $compile(addDeliveryAction)($scope);
                }

                $scope.setupDeliveriesGridColumns();

            }
        });

        function onGridKeydown(e)
        {
            if (e.keyCode === kendo.keys.TAB)
            {
                var grid = $("#deliveriesGrid").data("kendoGrid");

                var current = grid.current();

                if (!current.hasClass("k-editable-cell"))
                {
                    var nextCell = current.nextAll(".k-editable-cell");
                    if (!nextCell[0])
                    {
                        //search the next row
                        var nextRow = current.parent().next();

                        // if tabbing and reached the last cell then create new row
                        if (nextRow.length === 0)
                        {
                            $scope.addDeliveryGridRow();

                            var $newRow = $(grid.tbody.children().last());

                            $newRow.find("td:eq(0) input").focus();
                            nextCell = $newRow;
                            grid.current(nextCell);

                        } else
                        {
                            nextCell = current.parent().next().children(".k-editable-cell:first");

                            grid.current(nextCell);
                            grid.editCell(nextCell[0]);
                        }
                    } else
                    {
                        grid.current(nextCell);
                        grid.editCell(nextCell[0]);
                    }

                }
            }
        }

        $scope.setupDeliveryRows = function ()
        {

            $("#deliveriesGrid tr").each(function (index, row)
            {

                if (index > 1)
                { // skip header rows

                    $scope.setupDeliveryRow(row);
                }
            });
        };

        $scope.setupDeliveryRow = function (deliveryRow)
        {

            var dataItem = $("#deliveriesGrid").data("kendoGrid").dataItem(deliveryRow);

            var $row = $(deliveryRow);

            //set prod id and description for product key
            var selectedProduct = $scope.getProductByKey(dataItem.ProdKey);

            if (selectedProduct)
            {
                dataItem.set("ProdId", selectedProduct.Id);
                dataItem.set("ProdDesc", selectedProduct.Description);
            }

            var $rowColumns = $row.find('td');

            $rowColumns.each(function (index, col)
            {
                var $col = $(col);
                var fieldName = $col.attr('data-field');
                if (!deliveriesGridColIsEditable(fieldName, dataItem))
                {
                    $col.addClass("tableCellDisabled");
                    $col.removeClass("k-editable-cell");

                    /*if (fieldName === "DeleteDeliveryBtn") {
                     $col.find('i').hide();
                     }*/
                }

                $scope.setupGridColumnValidationNotificationUI($col);
            });
        };

        $scope.addDeliveryGridRow = function ()
        {
            var grid = $("#deliveriesGrid").data("kendoGrid");

            if (grid)
            {

                // check if there is a new row already and if it is not valid then don't create a another new row
                // if there is a invalid new row remove it before validating
                var $lastRow = $(grid.tbody.children().last());
                var isNewRow = $lastRow.hasClass('newRow');

                if (isNewRow)
                {
                    var hasDirtyRow = $lastRow.find('.k-dirty');

                    if (hasDirtyRow.length === 0)
                    {
                        grid.cancelRow();
                    }
                }

                $("deliveriesGrid").removeClass("newRow");

                //this logic creates a new item in the datasource/datagrid
                var dataSource = grid.dataSource;
                var totalRows = dataSource.data().length;

                var newDeliveryProduct = {
                    "AllocFuelContKey": null,
                    "AllocFuelContDtlKey": null,
                    "AllocLastPostedFuelContDtlKey": null,
                    "BasedOnUnits": 0,
                    "BOLAutoMatched": 0,
                    "BOLDtlKey": null,
                    "BusEntKey": null,
                    "CalcExtCost": 0,
                    "CalcFrtCost": 0,
                    "CalcUnitCost": 0,
                    "CnfrmDateTime": null,
                    "CnfrmStatus": 0,
                    "CnfrmUserID": null,
                    "ContractOverride": 0,
                    "CostOverride": 0,
                    "CostPosted": 0,
                    "CustLocKey": null,
                    "DelivDateTime": $scope.appInfo.paperwork.deliveries.fuelDelivery.DelivDateTime,
                    "DelivGrossQty": 0,
                    "DelivNetQty": 0,
                    "DestProdKey": null,
                    "DestSiteKey": $scope.appInfo.paperwork.batch.SiteKey,
                    "DestType": 0,
                    "ExternalRecID": null,
                    "FrtInInventory": -1,
                    "FrtOverride": 0,
                    "FrtPLAmt": 0,
                    "FrtPLInInvAmt": 0,
                    "FrtPLInInventory": -1,
                    "FueldelProdFrtReconGrossQty": 0,
                    "FueldelProdFrtReconNetQty": 0,
                    "FrtReconUserID": null,
                    "FrtReconciled": 0,
                    "FrtUnitCost": 0,
                    "FrtVndInvKey": null,
                    "FrtVndInvPndKey": null,
                    "FuelContKey": null,
                    "FuelContDtlKey": null,
                    "FuelDelKey": $scope.appInfo.paperwork.deliveries.fuelDelivery.Key,
                    "InvcExtCost": 0,
                    "InvcFrtCost": 0,
                    "InvcFrtUnitCost": 0,
                    "InvcGrossQty": 0,
                    "InvcNetQty": 0,
                    "InvcUnitCost": 0,
                    "Key": 0,
                    "LastPostedFuelContDtlKey": null,
                    "OrdQty": 0,
                    "OrigProdKey": null,
                    "OwnerOption": 0,
                    "ProdKey": "",
                    "ReconUserID": null,
                    "Reconciled": 0,
                    "SiteKey": $scope.appInfo.paperwork.batch.SiteKey,
                    "SourceKey": 0,
                    "SourceType": 1,
                    "TaxPLAmt": 0,
                    "TaxPLInInvAmt": 0,
                    "VndInvKey": null,
                    "VndInvPndKey": null,
                    "Timestamp": "",
                    "FuelDeliveryTanks": [
                        {
                            "DelivQty": 0,
                            "DelProdKey": 0,
                            "Ethanol": 0,
                            "InventoryCost": 0,
                            "Key": 0,
                            "LastCost": 0,
                            "LastDelivGrossQty": 0,
                            "LastDelivNetQty": 0,
                            "LastEthanol": 0,
                            "LastOctane": 0,
                            "LastQty": 0,
                            "LastRVP": 0,
                            "LastTankNo": null,
                            "Octane": 0,
                            "OrdQty": 0,
                            "RplCarrierKey": null,
                            "RplCostAssigned": 0,
                            "RplTrmnlKey": null,
                            "RplVendKey": null,
                            "RVP": 0,
                            "TankNo": "",
                            "Timestamp": ""
                        }
                    ],
                    ProdId: "",
                    ProdDesc: ""
                };

                dataSource.insert(totalRows, newDeliveryProduct);

                grid.editRow(grid.tbody.children().last());

                var $newRow = $(grid.tbody.children().last());
                $newRow.addClass("newRow");
                $scope.setupDeliveryRow($newRow);

            }
        };

        $scope.setupDeliveriesGridColumns = function ()
        {

            if (!$scope.appInfo.paperwork.deliveries.context.ShowGrossUnits)
            {
                $scope.kendo.deliveriesGrid.hideColumn("DelivGrossQty");
            }

            if (!$scope.appInfo.paperwork.deliveries.context.ShowNetUnits)
            {
                $scope.kendo.deliveriesGrid.hideColumn("DelivNetQty");
            }

            if (!$scope.deliveryIsEditable())
            {
                $scope.kendo.deliveriesGrid.hideColumn("DeleteDeliveryBtn");
            }

        };


        $scope.deleteDeliveryProductRow = function (dataItem)
        {

            var dataRow = $('#deliveriesGrid').data("kendoGrid").dataSource.getByUid(dataItem.uid);
            $('#deliveriesGrid').data("kendoGrid").dataSource.remove(dataRow);

        };

        function deliveriesGridColIsEditable(fieldName, model)
        {

            if (fieldName === "ProdKey")
            {
                return $scope.deliveryIsEditable();
            }

            if (fieldName === "ProdDesc")
            {
                return false;
            }

            if (fieldName === "TankNo")
            {
                return $scope.deliveryIsEditable();
            }

            if (fieldName === "DelivGrossQty")
            {
                return true;
            }

            if (fieldName === "DelivNetQty")
            {
                return true;
            }

            return true; // default to editable
        }

        $scope.setupGridColumnValidationNotificationUI = function ($col)
        {

            var fieldName = $col.attr('data-field');

            $col.qtip({
                id: "qtip-" + fieldName,
                content: {
                    text: ""
                },
                position: {
                    adjust: {
                        x: -10, y: -10
                    }
                },
                style: {
                    classes: "toolTipStyle"
                }
            });

            $col.qtip('disable', true);

        };

        $scope.processGridColumnValidationNotification = function ($row, fieldName, errorMessages, warningMessages)
        {

            var idx = 0;

            var hasErrors = (errorMessages.length > 0);
            var hasWarnings = (warningMessages.length > 0);

            var $col = $row.find("[data-field='" + fieldName + "']");

            //clear classes
            $col.removeClass("td-error");
            $col.removeClass("td-warning");

            if (hasErrors || hasWarnings)
            {

                var messageList = $("<div></div>");

                if (hasErrors)
                {
                    var errorList = $("<div style='padding-bottom: 2px'>" + localStrings.getLocalizedString("Error") + " :</div>");
                    for (idx = 0; idx < errorMessages.length; idx++)
                    {
                        errorList.append("<li style='padding: 1px 0px 1px 7px'>" + errorMessages[idx] + "</li>");
                    }

                    messageList.append(errorList);
                }

                if (hasWarnings)
                {
                    var warningList = $("<div style='padding - bottom: 2px'>" + localStrings.getLocalizedString("Warning") + " :</div>");
                    for (idx = 0; idx < warningMessages.length; idx++)
                    {
                        warningList.append("<li style='padding: 1px 0px 1px 7px'>" + warningMessages[idx] + "</li>");
                    }

                    messageList.append(warningList);
                }

                $col.qtip('option', 'content.text', messageList);

                if (hasErrors)
                {
                    $col.addClass("td-error");
                    $col.qtip('option', 'style.classes', 'toolTipStyle-error');
                } else
                {
                    $col.addClass("td-warning");
                    $col.qtip('option', 'style.classes', 'toolTipStyle-warning');
                }

                $col.qtip('disable', false);
            }
            else
            {
                $col.qtip('disable', true);
            }
        };

        if (!$scope.appInfo.paperwork.viewOnly)
        {
            $scope.$watch('appInfo.paperwork.deliveries.fuelDelivery.VendKey', function (newValue, oldValue)
            {
                if (angular.isUndefinedOrNullOrEmpty(newValue) || (newValue < 0) || (newValue === oldValue) || $scope.currentView === 0)
                {
                    return;
                }

                var vendKey = newValue;

                if ($scope.appInfo.paperwork.deliveries.context.UsesSiteAuthorizedTerminals)
                {  // uses selected or exculusive site authorized terminals

                    if (newValue === "")
                    {
                        $scope.appInfo.paperwork.deliveries.fuelDelivery.TrmnlKey = "";
                        $scope.appInfo.paperwork.deliveries.context.AuthorizedTerminals = [];
                        return;
                    }

                    PaperworkDeliveriesService.getSiteFuelAuthorizedOrigins(
                        $scope.appInfo.paperwork.batch.Key, vendKey
                    ).then(function (data)
                        {
                            angular.copy(data, $scope.appInfo.paperwork.deliveries.context.AuthorizedTerminals);

                            //save of bound terminal key before updating datasource
                            var trmnlKey = $scope.appInfo.paperwork.deliveries.fuelDelivery.TrmnlKey;

                            $scope.kendo.terminals.dataSource.data($scope.appInfo.paperwork.deliveries.context.AuthorizedTerminals);

                            $scope.appInfo.paperwork.deliveries.fuelDelivery.TrmnlKey = trmnlKey;

                        });
                }

            });
        }

        var navToMeters = $rootScope.$on('event:NavigateToMeters', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.previous('/Paperwork/ViewPaperwork/');
            else
                $scope.previous('/Paperwork/EnterPaperwork/');
        });

        $scope.$on('$destroy', navToMeters);

        var navToForms = $rootScope.$on('event:NavigateToForms', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.next('/Paperwork/ViewPaperworkDealerForms/');
            else
                $scope.next('/Paperwork/EnterPaperworkDealerForms/');
        });

        $scope.$on('$destroy', navToForms);

        var navToTanks = $rootScope.$on('event:NavigateToTanks', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.previous('/Paperwork/ViewPaperworkTanks/');
            else
                $scope.previous('/Paperwork/EnterPaperworkTanks/');
        });

        $scope.$on('$destroy', navToTanks);

        $scope.next = function (path)
        {
            if (!$scope.validateDeliveries())
                return;

            if ($("#appForm").hasClass("ng-dirty"))
            {

                if ($scope.appInfo.paperwork.viewOnly)
                {
                    $scope.goTo(path + $scope.appInfo.paperwork.paperworkKey);
                }
                else
                {
                    $scope.savePaperworkDelivery(path + $scope.appInfo.paperwork.paperworkKey, false, false);
                }
            } else
            {
                $scope.goTo(path + $scope.appInfo.paperwork.paperworkKey);
            }
        };

        $scope.previous = function (path)
        {
            if ($("#appForm").hasClass("ng-dirty"))
            {

                if ($scope.appInfo.paperwork.viewOnly)
                {
                    $scope.goTo(path + $scope.appInfo.paperwork.paperworkKey);
                }
                else
                {
                    $scope.savePaperworkDelivery(path + $scope.appInfo.paperwork.paperworkKey, false, false);
                }
            } else
            {
                $scope.goTo(path + $scope.appInfo.paperwork.paperworkKey);
            }


        };

        $scope.close = function (path)
        {
            if (!$scope.appInfo.paperwork.viewOnly)
            {
                $scope.savePaperworkDelivery("", false, false);
            } else
            {
                $scope.goTo("/Paperwork/EnterPaperwork/");
            }
        };

        $scope.submit = function ()
        {
            if (!$scope.validateDeliveries())
                return;

            if ($scope.currentView === 0 || $("#appForm").hasClass("ng-pristine"))
            {  // if in record selector just submit
                $scope.submitPaperwork(true);
            } else
            {  // if in form save and then submit

                $scope.savePaperworkDelivery("", true, false);
            }
        };

        $scope.submitPaperwork = function (submit)
        {
            var fuelMetersAppInfo = $scope.appInfo.paperwork.apps[0];

            //If we have fuelMeters and fuelMeters are required, but not saved; then throw error
            if (fuelMetersAppInfo.HasData === 0 && fuelMetersAppInfo.IsRequired !== 0) {
                $timeout(function () {
                    $scope.modalifier.showSavedError();
                }, 100);
                return;
            }

            var submitPaperworkCmdParms =
            {
                SiteKey: $scope.appInfo.paperwork.batch.SiteKey,
                PaperworkDate: $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true),
                EmailAddress: $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                SubmitPaperwork: submit,
                PreviouslySubmitted: $scope.appInfo.paperwork.batch.HasBeenSubmitted
            };
            PaperworkService.SubmitPaperwork(submitPaperworkCmdParms).then(function (data)
            {
                if (submit)
                    $scope.goTo("Paperwork/InventorySummary/" + $scope.appInfo.paperwork.batch.SiteKey + "/" + $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true));
            });
        };

        $scope.savePaperworkDelivery = function (path, submit, close)
        {
            var deliveriesGrid = $("#deliveriesGrid").data("kendoGrid");

            // if there is a invalid new row remove it before validating
            var $lastRow = $(deliveriesGrid.tbody.children().last());
            var isNewRow = $lastRow.hasClass('newRow');

            if (isNewRow)
            {
                var rowIsDirty = $lastRow.find('.k-dirty'); // find any columns that are dirty

                if (rowIsDirty.length === 0)
                {
                    deliveriesGrid.cancelRow();
                }
            }

            if (!validatorSvc.executeValidations() || !$scope.validateDeliveries())
            {
                return;
            }

            if ($scope.appInfo.paperwork.deliveries.appForm)
            {
                $scope.resetDirtyFlags($scope.appInfo.paperwork.deliveries.appForm);
            }

            // if current view is record selector then don't save fuel delivery. just navigate to selected path
            if ($scope.currentView === 0)
            {
                if (path.length > 0)
                {
                    $scope.goTo(path);
                    return;
                }
            }

            angular.copy($scope.deliveriesGridDS.data().toJSON(), $scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryProducts);

            // if new delivery then set entry user id
            if ($scope.appInfo.paperwork.deliveries.fuelDelivery.Key <= 0)
            {
                $scope.appInfo.paperwork.deliveries.fuelDelivery.EntryUserID = $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress;
            }
            var paperworkDeliveryHeader =
            {
                PaperworkBatchKey: $scope.appInfo.paperwork.batch.Key,
                AppKey: $scope.locationInfo.sideMenuData.paperwork.deliveries.Key
            };

            PaperworkDeliveriesService.savePaperworkDelivery(paperworkDeliveryHeader, $scope.appInfo.paperwork.deliveries.fuelDelivery)
                .then(function (data)
                {
                    data.FuelDelivery.LiftDatetime = globalizationSvc.formatDateTime(data.FuelDelivery.LiftDatetime, 'g');
                    data.FuelDelivery.DelivDateTime = globalizationSvc.formatDateTime(data.FuelDelivery.DelivDateTime, 'g');

                    $scope.appInfo.paperwork.deliveries.fuelDelivery = data.FuelDelivery;

                    $scope.appInfo.paperwork.deliveries.deliveriesList = data.FuelDeliveriesList;

                    // initalize product filtered tanks list
                    angular.forEach($scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryProducts, function (deliveryProduct)
                    {
                        deliveryProduct.FilteredTanksList = $scope.getFilteredTanksList(deliveryProduct.ProdKey);
                        deliveryProduct.SelectedTank = deliveryProduct.FilteredTanksList.find({TankNo: deliveryProduct.FuelDeliveryTanks[0].TankNo});
                    });

                    var grid = $("#deliveriesGrid").data("kendoGrid");
                    grid.dataSource.data(data.FuelDelivery.FuelDeliveryProducts);

                    $scope.validateDeliveries();

                    if (submit)
                    {
                        $scope.submitPaperwork(true);
                        return;
                    }
                    if (close)
                    {
                        $scope.submitPaperwork(false);
                        return;
                    }
                    if (path.length > 0)
                    {
                        $scope.goTo(path);
                    }
                });
        };

        $scope.displayDeleteConfirmationModal = function ()
        {

            $scope.deleteConfirmationModalDeferred = $q.defer();

            $scope.showDeleteConfirmationModal = true;

            return $scope.deleteConfirmationModalDeferred.promise;

        };

        $scope.deleteDeliveryHandler = function (deliveryListItem)
        {
            $scope.displayDeleteConfirmationModal().then(function ()
            { // ok handler
                $scope.deletePaperworkDelivery(deliveryListItem);
                $scope.deleteConfirmationModalDeferred = undefined;
                $scope.showDeleteConfirmationModal = false;
            }, function ()
            { // cancel handler
                $scope.deleteConfirmationModalDeferred = undefined;
                $scope.showDeleteConfirmationModal = false;
            });
        };

        $scope.deletePaperworkDelivery = function (deliveryListItem)
        {
            PaperworkDeliveriesService.deletePaperworkDelivery(deliveryListItem, $scope.appInfo.paperwork.batch.Key)
                .then(function (data)
                {
                    //update record selector list
                    $scope.appInfo.paperwork.deliveries.deliveriesList = data.FuelDeliveriesList;
                }
            );
        };

        $scope.displayCommentsModalForm = function ()
        {
            $scope.showCommentsEntryModalForm = true;
        };

        $scope.perLoadFreightModalFormDeferred = undefined;

        $scope.displayPerLoadFreightModalForm = function ()
        {

            $scope.perLoadFreightModalFormDeferred = $q.defer();

            $scope.freightModalFormDelegate.fuelDeliveryFreight = angular.copy($scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryFreight);

            $scope.showPerLoadFreightEntryModalForm = true;

            return $scope.perLoadFreightModalFormDeferred.promise;

        };

        $scope.editPerLoadFreight = function ()
        {
            $scope.displayPerLoadFreightModalForm().then(function ()
            { // ok handler
                $scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryFreight = angular.copy($scope.freightModalFormDelegate.fuelDeliveryFreight);
                $scope.freightModalFormDelegate.fuelDeliveryFreight = {};
                $scope.perLoadFreightModalFormDeferred = undefined;
                $scope.showPerLoadFreightEntryModalForm = false;
            }, function ()
            { // cancel handler
                $scope.perLoadFreightModalFormDeferred = undefined;
                $scope.showPerLoadFreightEntryModalForm = false;
            });
        };

        $scope.hideModals = function ()
        {
            $scope.showDeleteConfirmationModal = false;
            $scope.showCommentsEntryModalForm = false;
            $scope.showPerLoadFreightEntryModalForm = false;
            validatorSvc.clearAllValidations();
        };

        $scope.getFilteredTanksList = function (productKey)
        {
            var tanks = $scope.appInfo.paperwork.deliveries.context.SiteTanks;
            var siteTankProductLookup = $scope.appInfo.paperwork.deliveries.context.SiteTankProductLookup;

            var data = [];

            angular.forEach(siteTankProductLookup.findAll({ProductKey: productKey}), function (authorizedTank)
            {

                var tank = tanks.find({ProductKey: authorizedTank.TankProductKey});

                if (angular.isDefined(tank))
                {
                    data.push(tank);
                }
            });

            return data;
        };

        $scope.deliveryIsEditable = function (delivery)
        {
            if (delivery === undefined)
            {
                delivery = $scope.appInfo.paperwork.deliveries.fuelDelivery;
            }

            if ($("#deliveriesGrid").attr("k-editable") === "false")
            {
                return false;
            } else if (delivery.Status > 2) { // scheduled (0) or verified (1) or entered (2) = true else false
                return false;
            } else if ($scope.appInfo.paperwork.context.lockPaperworkAfterDru && delivery.DRUPosted) {
                return false;
            } else {
                return true;
            }
        };

        var deliveryGridRowValidation =
        {
            validator: function (deliveryProduct, parms)
            {
                var isValid = true;

                var $row = $("#deliveriesGrid").data("kendoGrid").tbody.find("tr[data-uid='" + deliveryProduct.uid + "']");

                //gross quantity validation
                if ($scope.appInfo.paperwork.deliveries.context.ShowGrossUnits)
                {

                    var errorGrossQty = [], warningGrossQty = [];

                    if (deliveryProduct.DelivGrossQty === 0)
                    {
                        isValid = false;

                        errorGrossQty.push(localStrings.getLocalizedString("GTZeroError"));
                    }

                    if ((deliveryProduct.DelivGrossQty > 0) && (deliveryProduct.DelivGrossQty > deliveryProduct.SelectedTank.Capacity ))
                    {

                        warningGrossQty.push(localStrings.getLocalizedString("QuantityExceedsTank"));

                    }

                    $scope.processGridColumnValidationNotification($row, 'DelivGrossQty', errorGrossQty, warningGrossQty);
                }

                //net quantity validation
                if ($scope.appInfo.paperwork.deliveries.context.ShowNetUnits)
                {

                    var errorNetQty = [], warningNetQty = [];

                    if (deliveryProduct.DelivNetQty === 0)
                    {
                        isValid = false;

                        errorNetQty.push(localStrings.getLocalizedString("GTZeroError"));
                    }

                    if ((deliveryProduct.DelivNetQty > 0) && (deliveryProduct.DelivNetQty > deliveryProduct.SelectedTank.Capacity ))
                    {
                        warningNetQty.push(localStrings.getLocalizedString("QuantityExceedsTank"));
                    }

                    $scope.processGridColumnValidationNotification($row, 'DelivNetQty', errorNetQty, warningNetQty);
                }

                return isValid;
            }
        };

        $scope.showErrorTooltip = function (el) {
            //$scope.setupGridColumnValidationNotificationUI(el);

            var fieldName = el.attr('data-field');

            el.qtip({
                id: "qtip-" + fieldName,
                content: {
                    text: ""
                },
                position: {
                    adjust: {
                        x: 15, y: -30
                    }
                },
                style: {
                    classes: "toolTipStyle"
                }
            });

            el.qtip('disable', true);

            el.qtip('option', 'content.text', localStrings.getLocalizedString('DelivDetailsRequired'));
            el.qtip('option', 'style.classes', 'toolTipStyle-error');
            el.qtip('disable', false);
        };

        $scope.validateDeliveries = function ()
        {
            // checks if control is not null, undefined and so on ...
            if (!$("#deliveriesGrid").data("kendoGrid"))
                return true;

            var deliveriesGridRowsCount = $("#deliveriesGrid").data("kendoGrid").dataSource.data().length;

            if (deliveriesGridRowsCount === 0 && !$("#appForm").hasClass("ng-dirty")) {
                return true;
            }
               
            if (!validatorSvc.executeValidations()) {
                return deliveriesGridRowsCount === 0;
            }

            var $gridActionFooter = $("#petronetGridActionFooterRow");
            var $gridActionFooterRowMiddle = $("#petronetGridActionFooterRowMiddle");

            $gridActionFooter.removeClass("td-error");
            $gridActionFooterRowMiddle.removeClass("td-error");

            var isValid = true;

            if (deliveriesGridRowsCount === 0) {

                $gridActionFooter.addClass("td-error");
                $gridActionFooterRowMiddle.addClass("td-error");

                $scope.showErrorTooltip($gridActionFooter);
                $scope.showErrorTooltip($gridActionFooterRowMiddle);

                return false;
            }

            var deliveriesGrid = $("#deliveriesGrid").data("kendoGrid");

            angular.forEach(deliveriesGrid.dataSource.data(), function (delivery)
            {
                isValid = isValid && deliveryGridRowValidation.validator(delivery);
            });

            return isValid;
        };

        $scope.getStatusDescription = function ()
        {
            if ($scope.appInfo.paperwork.deliveries.fuelDelivery.Status === 0)
            {
                return 'Scheduled';
            } else if ($scope.appInfo.paperwork.deliveries.fuelDelivery.Status === 1)
            {
                return 'Verified';
            }
            if ($scope.appInfo.paperwork.deliveries.fuelDelivery.Status === 2)
            {
                return 'Entered';
            }
            if ($scope.appInfo.paperwork.deliveries.fuelDelivery.Status === 3)
            {
                return 'Posted';
            }
            if ($scope.appInfo.paperwork.deliveries.fuelDelivery.Status === 4)
            {
                return 'Confirmed';
            }
            if ($scope.appInfo.paperwork.deliveries.fuelDelivery.Status === 5)
            {
                return 'Reconciled';
            }
        };

        var liftDateValidation =
        {
            name: 'validateLiftDate',
            validationMessage: '',
            validationMessageWrn: '',
            validatorError: function (liftDateTime, parms)
            {
                var isValid = true;

                return isValid;
            },
            validatorWarning: function (liftDateTime, parms)
            {
                if (angular.isUndefinedOrNullOrEmpty(liftDateTime))
                {
                    return true;
                }

                var hasWarning = false;
                var warningMsg = '';

                if ($scope.appInfo.paperwork.deliveries.fuelDelivery.DelivDateTime > liftDateTime)
                {
                    warningMsg = localStrings.getLocalizedString("DeliveryDateNot");
                    hasWarning = true;
                }

                var maxDateTime = $scope.addDaysToDate($scope.formatDateTime($scope.appInfo.paperwork.batch.PaperworkDate), 1);
                var minDateTime = $scope.addDaysToDate($scope.formatDateTime($scope.appInfo.paperwork.batch.PaperworkDate), -1);

                if ((liftDateTime > $scope.formatDateTime(maxDateTime)) || (liftDateTime < $scope.formatDateTime(minDateTime)))
                {
                    if (warningMsg !== '')
                    {
                        warningMsg = warningMsg + '\r\n';
                    }
                    warningMsg = warningMsg + localStrings.getLocalizedString("LiftDateNotWithin");
                    hasWarning = true;
                }

                this.validationMessageWrn = warningMsg;

                return !hasWarning;
            }
        };

        $scope.validateLiftDate = new pdi.ng.validate.PdiValidation(liftDateValidation);

        var deliveryDateValidation =
        {
            name: 'validateDeliveryDate',
            validationMessage: '',
            validationMessageWrn: '',
            validatorError: function (deliveryDateTime, parms)
            {
                var isValid = true;

                return isValid;
            },
            validatorWarning: function (deliveryDateTime, parms)
            {
                var hasWarning = false;

                var maxDateTime = $scope.addDaysToDate($scope.formatDateTime($scope.appInfo.paperwork.batch.PaperworkDate), 1);
                var minDateTime = $scope.addDaysToDate($scope.formatDateTime($scope.appInfo.paperwork.batch.PaperworkDate), -1);

                if ((deliveryDateTime > $scope.formatDateTime(maxDateTime)) || (deliveryDateTime < $scope.formatDateTime(minDateTime)))
                {
                    this.validationMessageWrn = localStrings.getLocalizedString("DeliveryDateNotWithin");
                    hasWarning = true;
                }

                return !hasWarning;
            }
        };

        $scope.validateDeliveryDate = new pdi.ng.validate.PdiValidation(deliveryDateValidation);


        $scope.viewDelivery = function (deliveryKey)
        {
            PaperworkDeliveriesService.getDelivery(
                deliveryKey,
                $scope.appInfo.paperwork.batch.Key
            ).then(function (data)
                {
                    $scope.appInfo.paperwork.deliveries.fuelDelivery = {};

                    data.LiftDatetime = globalizationSvc.formatDateTime(data.LiftDatetime, 'g');
                    data.DelivDateTime = globalizationSvc.formatDateTime(data.DelivDateTime, 'g');

                    $scope.appInfo.paperwork.deliveries.fuelDelivery = data;

                    $scope.freightModalFormDelegate.fuelDeliveryFreight = $scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryFreight;

                    // initalize product filtered tanks list
                    angular.forEach($scope.appInfo.paperwork.deliveries.fuelDelivery.FuelDeliveryProducts, function (deliveryProduct)
                    {
                        deliveryProduct.FilteredTanksList = $scope.getFilteredTanksList(deliveryProduct.ProdKey);
                        deliveryProduct.SelectedTank = deliveryProduct.FilteredTanksList.find({TankNo: deliveryProduct.FuelDeliveryTanks[0].TankNo});
                    });

                    $scope.initializeDeliveriesGrid();

                    $scope.changeView(1);

                });
        };

        $scope.editPerLoadFreightOkHandler = function ()
        {

            if (!validatorSvc.executeValidationsForScope($scope.freightModalFormDelegate))
            {
                return;
            }

            // workaround for the above code does not set the validatity of the model correctly. maybe a timing issue
            $timeout(function ()
            {

                var hasError = $("#deliveryFreightTable").find(".td-error");

                if (hasError.length > 0)
                {
                    return;
                }

                $scope.perLoadFreightModalFormDeferred.resolve();
            }, 100);
        };

        $scope.editPerLoadFreightCancelHandler = function ()
        {
            $scope.perLoadFreightModalFormDeferred.reject();
        };

    })
    .controller('enterPaperworkDeliveriesFreightModalCtrl', function ($scope, $rootScope, $routeParams, PaperworkService, $filter,
                                              PaperworkDeliveriesService, $location, WebFunctions, validatorSvc, $timeout, localizationSvc, globalizationSvc)
    {
        'use strict';

        $scope.modalInfo = {};
        $scope.kInputOptions = {
            culture: globalizationSvc.getLocale(),
            format: "n",
            decimals: 2,
            spinners: false
        };

        var parentController = angular.element('#deliveriesForm').scope();

        parentController.freightModalFormDelegate = $scope;

        $scope.fuelDeliveryFreight = {};

        $scope.addDeliveryFreightRow = function ()
        {
            var newFreightRow = {
                "CalcAmt": 0,
                "DelProdKey": null,
                "FrtVndInvKey": null,
                "FrtVndInvPndKey": null,
                "FrtRuleKey": null,
                "FuelDelKey": $scope.appInfo.paperwork.deliveries.fuelDelivery.Key,
                "InvcAmt": 0,
                "Key": 0,
                "Override": -1,
                "ReconUserID": null,
                "Reconciled": 0,
                "Type": 6,
                "Timestamp": ""
            };

            $scope.fuelDeliveryFreight.push(newFreightRow);
        };


        var freightRowValidation =
        {
            name: 'validateFreightRow',
            validationMessage: '',
            validatorError: function (freight, parms)
            {
                var isValid = true;

                //reset Errors
                freight.freightRuleValidation = {hasErrors: false, errorDescription: ""};

                if (angular.isUndefinedOrNullOrEmpty(freight.FrtRuleKey))
                {
                    isValid = false;
                    freight.freightRuleValidation = { hasErrors: true, errorDescription: localStrings.getLocalizedString("Required") };
                }

                if (isValid)
                { //cleanup view model
                    freight.freightRuleValidation = {};
                }

                return isValid;
            }
        };

        $scope.validateFreightRow = new pdi.ng.validate.PdiValidation(freightRowValidation);

    })
    .controller('enterPaperworkDealerFormsCtrl', function ($scope, $rootScope, $routeParams, PaperworkService, $filter, PaperworkDealerFormsService, $location, WebFunctions, $timeout, templates, globalizationSvc)
    {
        'use strict';
        $scope.dealerFormsLoaded = false;

        // controller destructor
        $scope.$on("$destroy", function handler()
        {
            $rootScope.scopesToValidate = [];
        });

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }        

            $scope.appInfo.paperwork.viewOnly = $location.$$path.indexOf('/ViewPaperworkDealerForms/') > -1;

            $scope.formsEditable = true;

            if ($routeParams.paperworkKey)
            {
                $scope.appInfo.paperwork.paperworkKey = $routeParams.paperworkKey;
            }
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;
            if (!$scope.locationInfo.sideMenuData) $scope.locationInfo.sideMenuData = {};
            $scope.locationInfo.sideMenu = templates.getTemplate("PaperworkSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = '1';
            $scope.appInfo.paperwork.batch = {};

            // TODO To add the ability to add this to every possible field, we would run a foreach for each field in loadDealerForms and then attach this with their precision
            $scope.appInfo.paperwork.kOptions = {
                culture: globalizationSvc.getLocale(),
                format: "n",
                decimals: 2,
                spinners: false,
                min: -999999999
            };

            if (!$scope.appInfo.paperwork.context)
            {
                PaperworkService.getPaperworkContext($scope.appInfo.webUser.CurrentAuthorization.CustContKey, true).then(
                    function (data)
                    {
                        $scope.appInfo.paperwork.context = {};
                        $scope.appInfo.paperwork.context.paperworkSites = data.PaperworkSites;
                        $scope.appInfo.paperwork.context.selectedSite = data.SelectedSiteKey;
                        $scope.appInfo.paperwork.context.lockPaperworkAfterDru = data.LockPaperworkAfterDru;

                        $scope.appInfo.paperwork.context.calculatedPriceToPriorDaysPriceVariance = data.CalculatedPriceToPriorDaysPriceVariance;
                        $scope.appInfo.paperwork.context.calculatedPriceToCapturedRetailPriceVariance = data.CalculatedPriceToCapturedRetailPriceVariance;
                        $scope.appInfo.paperwork.context.netSalesDollarsToCapturedRetailPriceNetUnitsVariance = data.NetSalesDollarsToCapturedRetailPriceNetUnitsVariance;

                        $scope.locationInfo.showEnterPaperworkMenuItem = $scope.appInfo.paperwork.context.paperworkSites[0] ?
                            $scope.appInfo.paperwork.context.paperworkSites[0].CanEnterPaperwork :
                            false;

                        if (!$scope.locationInfo.showEnterPaperworkMenuItem)
                        {
                            $scope.locationInfo.sideMenuData.subMenu = '2';
                            $location.path("/Paperwork/ReviewPaperwork");
                        }
                    }
                );
            }

            if ($scope.appInfo.paperwork.paperworkKey > 0)
            {
                //We are loading a known existing paperwork batch
                PaperworkService.OpenPaperworkBatchByKey(
                    $scope.appInfo.paperwork.paperworkKey,
                    $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                    $scope.appInfo.webUser.Id,
                    $scope.appInfo.paperwork.viewOnly
                ).then(function (data)
                    {
                        $scope.appInfo.paperwork.batch = data.PaperworkBatch.PaperworkBatch;
                        $scope.appInfo.paperwork.apps = data.PaperworkBatch.PaperworkApps;
                        $scope.appInfo.paperwork.form = data.PaperworkBatch.PaperworkForm;
                        $scope.appInfo.paperwork.siteInfo = data.PaperworkBatch.PaperworkSiteInfo;

                        $rootScope.$broadcast('event:PaperworkLoaded', $scope.appInfo.paperwork.viewOnly, $scope.appInfo.paperwork.paperworkKey);

                        var nextApp = true;
                        angular.forEach($scope.appInfo.paperwork.apps, function (app, index)
                        {
                            if (app.MenuCode === 'DRDFE')
                            {
                                $scope.loadDealerForms();
                                nextApp = false;
                            }
                        });
                        if (nextApp)
                        {
                            $scope.goTo("Paperwork/InventorySummary/" + $scope.appInfo.paperwork.batch.SiteKey + "/" + $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true));
                        }
                    }, function ()
                    {
                        $location.path("/Paperwork/ReviewPaperwork");
                    });
            } else
            {
                //todo: goback...
            }
            $scope.locationInfo.sideMenuData.pwSubMenu = '3';
        };

        var newPWBatchLoadedListener = $rootScope.$on('event:NewPaperworkBatchLoaded', function (event, data)
        {
            $scope.loadDealerForms();
        });

        $scope.$on('$destroy', newPWBatchLoadedListener);

        $scope.loadDealerForms = function ()
        {
            angular.forEach($scope.appInfo.paperwork.context.paperworkSites, function (site, index)
            {
                if (site.SiteKey === $scope.appInfo.paperwork.batch.SiteKey)
                    $scope.appInfo.paperwork.context.selectedSiteObj = site;
            });

            PaperworkDealerFormsService.loadDealerForms($scope.appInfo.paperwork.batch.Key).then(function (data)
            {
//                    if(data.count == 0){
                //TODO: No forms, do something...
//                  }
                $scope.appInfo.paperwork.dealerForms = data.DealerForms;
                $scope.setupDisplayValues();

                $scope.dealerFormsLoaded = true;

                $scope.formsEditable = !($scope.appInfo.paperwork.context.lockPaperworkAfterDru && $scope.appInfo.paperwork.dealerForms.Header.Updated);

                $timeout(function(){
                    if (!$scope.formsEditable) {
                        $('#sectionDealerForms input').attr("disabled", true);
                    }
                });

                $scope.updateDealerForms();
                $scope.setFocus();
            });
        };

        $scope.setupDisplayValues = function () {
            $scope.appInfo.paperwork.dealerForms.AllFieldsDisplay = {};

            angular.forEach($scope.appInfo.paperwork.dealerForms.AllFields, function (field, index) {
                var newObject = {};

                angular.forEach(Object.keys(field), function (property, index) {
                    if (property === "DataIDValue")
                        newObject[property] = globalizationSvc.formatNumeric(field[property], "n2");
                });

                $scope.appInfo.paperwork.dealerForms.AllFieldsDisplay[field.Key] = newObject;
            });
        };

        var navToMeters = $rootScope.$on('event:NavigateToMeters', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.previous('/Paperwork/ViewPaperwork/');
            else
                $scope.previous('/Paperwork/EnterPaperwork/');
        });

        $scope.$on('$destroy', navToMeters);

        var navToTanks = $rootScope.$on('event:NavigateToTanks', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.previous('/Paperwork/ViewPaperworkTanks/');
            else
                $scope.previous('/Paperwork/EnterPaperworkTanks/');
        });

        $scope.$on('$destroy', navToTanks);

        var navToDeliveries = $rootScope.$on('event:NavigateToDeliveries', function (event, args)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.previous('/Paperwork/ViewPaperworkDeliveries/');
            else
                $scope.previous('/Paperwork/EnterPaperworkDeliveries/');
        });

        $scope.$on('$destroy', navToDeliveries);

        $scope.next = function ()
        {
            if (!$scope.appInfo.paperwork.viewOnly)
                $scope.saveDealerForms("", false, true);
        };

        $scope.previous = function (path)
        {
            if ($scope.appInfo.paperwork.viewOnly)
                $scope.goTo(path + $scope.appInfo.paperwork.paperworkKey);
            else
                $scope.saveDealerForms(path + $scope.appInfo.paperwork.paperworkKey, false, false);
        };

        $scope.close = function ()
        {
            if (!$scope.appInfo.paperwork.viewOnly)
            {
                $scope.saveDealerForms("", false, false);
            } else
            {
                $scope.goTo("/Paperwork/EnterPaperwork/");
            }
        };
        $scope.submit = function ()
        {
            $scope.saveDealerForms("", true, false);
        };

        $scope.submitPaperwork = function (submit)
        {
            var fuelMetersAppInfo = $scope.appInfo.paperwork.apps[0];

            //If we have fuelMeters and fuelMeters are required, but not saved; then throw error
            if (fuelMetersAppInfo.HasData === 0 && fuelMetersAppInfo.IsRequired !== 0) {
                $timeout(function () {
                    $scope.modalifier.showSavedError();
                }, 100);
                return;
            }

            var submitPaperworkCmdParms =
            {
                SiteKey: $scope.appInfo.paperwork.batch.SiteKey,
                PaperworkDate: $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true),
                EmailAddress: $scope.appInfo.webUser.CurrentAuthorization.CustomerContact.EmailAddress,
                SubmitPaperwork: submit,
                PreviouslySubmitted: $scope.appInfo.paperwork.batch.HasBeenSubmitted
            };
            PaperworkService.SubmitPaperwork(submitPaperworkCmdParms).then(function (data)
            {
                if (submit)
                    $scope.goTo("Paperwork/InventorySummary/" + $scope.appInfo.paperwork.batch.SiteKey + "/" + $scope.urlFormatDate($scope.appInfo.paperwork.batch.PaperworkDate, true));
            });
        };

        $scope.saveDealerForms = function (path, submit, close)
        {
            if ($scope.appInfo.paperwork.dealerForms) {
                $scope.appInfo.paperwork.dealerForms.Header.UserName = $scope.appInfo.paperwork.form.UserName;
                $scope.appInfo.paperwork.form.Caption = $scope.appInfo.paperwork.dealerForms.DealerFormVersion.Caption;
                PaperworkDealerFormsService.saveDealerForms($scope.appInfo.paperwork.dealerForms.Header,
                    $scope.appInfo.paperwork.dealerForms.AllFields).then(function (data) {
                        $scope.appInfo.paperwork.dealerForms = data.DealerForms;
                        $scope.setupDisplayValues();

                        if (submit) {
                            $scope.submitPaperwork(true);
                            return;
                        } else if (close) {
                            $scope.submitPaperwork(false);
                            return;
                        }
                    });
            }
            if (path.length > 0) {
                $scope.goTo(path);
            }
        };

        $scope.setFocus = function ()
        {
            var focusSet = false;
            for (var x = 0; x < $scope.appInfo.paperwork.dealerForms.Rows[0].length; x++)
            {
                for (var y = 0; y < $scope.appInfo.paperwork.dealerForms.Rows.length; y++)
                {
                    var key = $scope.appInfo.paperwork.dealerForms.Rows[y][x];
                    if (key > 0)
                    {
                        if (!$scope.appInfo.paperwork.dealerForms.AllFields[key].DisplayOnly && !$scope.appInfo.paperwork.dealerForms.AllFields[key].IsCalculated && !focusSet)
                        {
                            $scope.appInfo.paperwork.dealerForms.AllFields[key].setFocus = true;
                            focusSet = true;
                        }
                        else
                            $scope.appInfo.paperwork.dealerForms.AllFields[key].setFocus = false;
                    }
                }
            }
        };

        $scope.onDealerFormChange = function (index, value) {
            $scope.appInfo.paperwork.dealerForms.AllFields[index].DataIDValue = parseFloat(globalizationSvc.convertStringToNumber($scope.appInfo.paperwork.dealerForms.AllFieldsDisplay[index].DataIDValue));
        };

        $scope.updateDealerForms = function ()
        {
            angular.forEach($scope.appInfo.paperwork.dealerForms.CalcFields, function (calcField, index)
            {//look through all the calc field references
                
                $scope.appInfo.paperwork.dealerForms.AllFields[calcField.Key].DataIDValue = 0;//We actually store all values in AllFields collection
                angular.forEach($scope.appInfo.paperwork.dealerForms.Calcuations, function (calc, index)
                {
                    if (calc.ResultTypeKey === calcField.TypeKey)
                    {//If the result of the calculation is stored on this calc field then...
                        angular.forEach($scope.appInfo.paperwork.dealerForms.AllFields, function (field, index)
                        {
                            $scope.appInfo.paperwork.dealerForms.AllFieldsDisplay[index].DataIDValue = globalizationSvc.formatNumeric($scope.appInfo.paperwork.dealerForms.AllFields[index].DataIDValue, "n2");

                            if (calc.CalcTypeKey === field.TypeKey)
                            {//If this field is the calc type from this calculation then
                                if (calc.Operation === 0)
                                {//Add
                                    $scope.appInfo.paperwork.dealerForms.AllFields[calcField.Key].DataIDValue =
                                        $scope.appInfo.paperwork.dealerForms.AllFields[calcField.Key].DataIDValue +
                                        field.DataIDValue;//Store the data back in the AllFields collection.
                                } else
                                {//Subtract
                                    $scope.appInfo.paperwork.dealerForms.AllFields[calcField.Key].DataIDValue =
                                        $scope.appInfo.paperwork.dealerForms.AllFields[calcField.Key].DataIDValue -
                                        field.DataIDValue;//Store the data back in the AllFields collection.
                                }
                            }
                        });
                    }
                });
            });
        };
    })
    .controller('inventorySummaryCtrl', function ($scope, $rootScope, WebFunctions, $timeout, templates)
    {
        'use strict';

        // controller destructor
        $scope.$on("$destroy", function handler()
        {
            $rootScope.scopesToValidate = [];
        });

        $scope.initialize = function ()
        {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry))
            {
                $scope.goTo("/Home");
            }

            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 30;
            $scope.locationInfo.sideMenu = templates.getTemplate("PaperworkSideMenu");
            $scope.locationInfo.sideMenuData.subMenu = '3';

            $timeout(function ()
            {
                var inventorySideMenuController = angular.element('#inventorySummarySideMenu').scope();

                inventorySideMenuController.setSiteAndDate();
            });


        };
    })
    .controller("paperworkReportsSideMenuCtrl", function ($scope)
    {
        'use strict';
        $scope.initialize = function () {
            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 31;
            $scope.locationInfo.sideMenuData = $scope.locationInfo.sideMenuData || {};
        };
    })
    .controller("paperworkReportSideMenuCtrl", function ($scope, PaperworkService, PaperworkReportsService, validatorSvc, reportingService, globalizationSvc) {
        'use strict';
        $scope.initialize = function () {

            if (!$scope.locationInfo.sideMenuData.subMenu) {
                $scope.goTo("/Paperwork/Reports");
                return;
            }

            //There is a race condition when reloading the page
            //Check if the date selector is undefined, if it is then initialize it
            if (angular.isUndefinedOrNullOrEmpty($scope.dateSelector)) {
                $scope.initializeDateSelector();
            }

            $scope.dateSelector.overrideDefaults = true;
            $scope.dateSelector.displaySelector = true;
            $scope.dateSelector.selection = 0;
            $scope.dateSelector.hideAllDays = true;

            var report = $scope.getReportFromSubMenu($scope.locationInfo.sideMenuData.subMenu);

            if (!report) {
                $scope.goTo("/Paperwork/Reports");
                return;
            }

            PaperworkReportsService
                .getPaperworkReportSites(report.Name, $scope.appInfo.webUser.CurrentAuthorization.CustContKey, false)
                .then(function (data) {
                    if (data.Successful && !angular.isUndefinedOrNullOrEmpty(data.PaperworkReportSites)) {
                        $scope.appInfo.paperwork.reports.PaperworkReportSites = data.PaperworkReportSites;
                        $scope.appInfo.paperwork.reports.site = data.PaperworkReportSites[0];
                    }
                    else {
                        $scope.modalifier.showSetupError();
                    }
                }
            );
        };

        $scope.getReportFromSubMenu = function (subMenu) {
            if ($scope.appInfo.paperwork.reports.paperworkReports) {
                return $scope.appInfo.paperwork.reports.paperworkReports
                    .filter(function (item) {
                        return item.Name === subMenu;
                    })[0];
            }
        };

        $scope.$on('exportingReport', function (event, args) {
            $scope.applyAction(args.format);
        });

        $scope.applyAction = function (format) {
            if (!validatorSvc.executeValidations()) {
                return;
            }

            var report = $scope.getReportFromSubMenu($scope.locationInfo.sideMenuData.subMenu);

            if (!report) {
                $scope.goTo("/Paperwork/Reports");
                return;
            }

            PaperworkService
                .ValidatePaperworkSiteDateForContact(
                    $scope.appInfo.paperwork.reports.site.Key,
                    $scope.urlFormatDate($scope.dateSelector.startDate, true),
                    $scope.appInfo.webUser.CurrentAuthorization.CustContKey,
                    false)
                .then(function (data) {
                    var qs = "reportName=" + report.Name +
                             "&reportSubjectArea=" + report.SubjectArea +
                             "&isStoreReport=" + "1" +
                             "&RP-Site=" + $scope.appInfo.paperwork.reports.site.Id +
                             "&RP-BegDate=" + $scope.dateSelector.startDate +
                             "&RP-EndDate=" + $scope.dateSelector.endDate +
                             "&RP-Language=" + globalizationSvc.getLocale().slice(0, 2) +
                             "&RP-UseLatest=" + "0";

                    if (format) {
                        reportingService.exportReport(qs, format);
                    }
                    else {
                        reportingService.renderReport(qs);
                    }
            });
        };
    })
    .controller('paperworkReportCtrl', function ($scope, $rootScope, WebFunctions, templates) {
        'use strict';

        // controller destructor
        $scope.$on("$destroy", function handler() {
            $rootScope.scopesToValidate = [];
        });

        $scope.initialize = function () {
            if (!$scope.checkCurrentUserPermission(WebFunctions.PaperworkEntry)) {
                $scope.goTo("/Home");
                return;
            }

            if (!$scope.locationInfo.sideMenuData.subMenu) {
                $scope.goTo("/Paperwork/Reports");
                return;
            }

            $scope.locationInfo.menuLocation = 3;
            $scope.locationInfo.subMenuLocation = 31;
            $scope.locationInfo.sideMenu = templates.getTemplate("ReportsSideMenu");
        };
    })
    .controller("paperworkSideMenuCtrl", function ($scope, PaperworkService, $rootScope, $interval)
    {
        'use strict';

        /*
            Use $interval to periodically refresh the paperwork lock.
            When the user navigates away from the page the $destroy listener is triggered to kill off the $interval.
            Likewise, the $interval is stopped when the paperwork is submitted in services.paperwork.
            This is paired with a query in CustomerRepository in EntWeb that wipes out Petronet Locks if they are > 20 minutes old.
        */

        var intervalPromise;
        var paperworkBatchKey = 0;
        var intervalTime = 120000;//Every 2 mins

        function refreshLock() {
            if (paperworkBatchKey > 0) {
                console.log("Lock refreshed for Batch#" + paperworkBatchKey);
                PaperworkService.RefreshPaperworkLock(paperworkBatchKey);
            }
        }

        $scope.startLockInterval = function (data) {
            $scope.stopLockInterval();
            intervalPromise = $interval(refreshLock, intervalTime);
        };

        $scope.stopLockInterval = function () {
            $interval.cancel(intervalPromise);
        };

        //When we load a paperwork batch, detect if we need to periodically refresh the lock
        var pwLockListener = $rootScope.$on('event:PaperworkLoaded', function (event, viewOnly, data) {
            if (!viewOnly && !angular.isUndefinedOrNullOrEmpty(data)) {
                paperworkBatchKey = data;
                $scope.startLockInterval();
            }
        });

        //When paperwork is submitted stop the lock refresh
        var pwSubmitListener = $rootScope.$on('event:PaperworkSubmitted', function () {
            $scope.stopLockInterval();
            paperworkBatchKey = 0;
        });

        //When we navigate away from this page destroy the listeners and interval
        $scope.$on('$destroy', function () {
            $scope.stopLockInterval();
            pwLockListener();
            pwSubmitListener();
            paperworkBatchKey = 0;
        });

    });